define('cat-frontend/tests/controllers/document.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers');
  QUnit.test('controllers/document.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'controllers/document.js should pass jshint.'); 
  });

});