define('cat-frontend/templates/components/chunks-editor-suggestions-box', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 177
                },
                "end": {
                  "line": 1,
                  "column": 275
                }
              },
              "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","concordance__spinner fa fa-spin fa-refresh fa-5x");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.10",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 283
                  },
                  "end": {
                    "line": 1,
                    "column": 647
                  }
                },
                "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","concordance__text");
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","concordance__text_source");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","concordance__text_lang");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(": ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","concordance__text_translation");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","concordance__text_lang");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(": ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [0]);
                var element1 = dom.childAt(element0, [0]);
                var element2 = dom.childAt(element0, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]),0,0);
                morphs[1] = dom.createUnsafeMorphAt(element1,1,1);
                morphs[2] = dom.createMorphAt(dom.childAt(element2, [0]),0,0);
                morphs[3] = dom.createUnsafeMorphAt(element2,1,1);
                return morphs;
              },
              statements: [
                ["content","suggestion.lang2",["loc",[null,[1,437],[1,457]]]],
                ["content","suggestion.text2",["loc",[null,[1,471],[1,493]]]],
                ["content","suggestion.lang1",["loc",[null,[1,579],[1,599]]]],
                ["content","suggestion.text1",["loc",[null,[1,613],[1,635]]]]
              ],
              locals: ["suggestion"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 275
                },
                "end": {
                  "line": 1,
                  "column": 656
                }
              },
              "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","concordanceSuggestions",["loc",[null,[1,291],[1,313]]]]],[],0,null,["loc",[null,[1,283],[1,656]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 156
              },
              "end": {
                "line": 1,
                "column": 667
              }
            },
            "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","concordanceSuggestions",["loc",[null,[1,187],[1,209]]]]],[],0,1,["loc",[null,[1,177],[1,667]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 667
              },
              "end": {
                "line": 1,
                "column": 771
              }
            },
            "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","concordance__error-text");
            var el2 = dom.createTextNode("There is no such expression in the translation memory");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 30
            },
            "end": {
              "line": 1,
              "column": 788
            }
          },
          "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","concordance__word");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","concordance__text_wrapper");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","wordForConcordance",["loc",[null,[1,88],[1,110]]]],
          ["block","unless",[["get","showError",["loc",[null,[1,166],[1,175]]]]],[],0,1,["loc",[null,[1,156],[1,782]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 826
              },
              "end": {
                "line": 1,
                "column": 943
              }
            },
            "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","translation-suggestion",[],["suggestion",["subexpr","@mut",[["get","suggestion",["loc",[null,[1,899],[1,909]]]]],[],[]],"translatedChunk",["subexpr","@mut",[["get","translatedChunk",["loc",[null,[1,926],[1,941]]]]],[],[]]],["loc",[null,[1,863],[1,943]]]]
          ],
          locals: ["suggestion"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1541
              },
              "end": {
                "line": 1,
                "column": 1728
              }
            },
            "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","http://aka.ms/MicrosoftTranslatorAttribution");
            dom.setAttribute(el1,"target","_blank");
            dom.setAttribute(el1,"class","ms-mt-link");
            var el2 = dom.createTextNode("Translated by");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","/assets/MSFT_logo_rgb_C-Gray.png");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 788
            },
            "end": {
              "line": 1,
              "column": 1741
            }
          },
          "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("ol");
          dom.setAttribute(el1,"class","suggestions__list");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","suggestions__legend");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","suggestions__line");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","legend__item");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","legend__symbol exact-match");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","legend__title");
          var el4 = dom.createTextNode("Exact match");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","legend__item");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","legend__symbol user-suggested-match");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","legend__title");
          var el4 = dom.createTextNode("User suggestion");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","legend__item");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","legend__symbol fuzzy-match");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","legend__title");
          var el4 = dom.createTextNode("Fuzzy match");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","legend__item");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","legend__symbol mt-match");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","legend__title");
          var el4 = dom.createTextNode("Machine translation");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]),5,5);
          return morphs;
        },
        statements: [
          ["block","each",[["get","suggestions",["loc",[null,[1,834],[1,845]]]]],[],0,null,["loc",[null,[1,826],[1,952]]]],
          ["block","if",[["get","translatedChunk.hasMTResults",["loc",[null,[1,1547],[1,1575]]]]],[],1,null,["loc",[null,[1,1541],[1,1735]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1754
          }
        },
        "moduleName": "cat-frontend/templates/components/chunks-editor-suggestions-box.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","suggestions__box");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","wordForConcordance",["loc",[null,[1,36],[1,54]]]]],[],0,1,["loc",[null,[1,30],[1,1748]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});