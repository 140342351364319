define('cat-frontend/templates/components/tm-controls', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 168
          }
        },
        "moduleName": "cat-frontend/templates/components/tm-controls.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        dom.setAttribute(el1,"class","controls__list_li");
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","controls");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3,"class","fa fa-trash fa-lg");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","controls__text");
        var el4 = dom.createTextNode("Delete");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 0]);
        var morphs = new Array(1);
        morphs[0] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [
        ["element","action",["deleteTM",["get","tm",["loc",[null,[1,53],[1,55]]]]],["on","click"],["loc",[null,[1,33],[1,68]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});