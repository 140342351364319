define('cat-frontend/controllers/tms/new', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    notUploaded: true,
    isLoading: false,
    isPublic: Ember['default'].computed.alias('model.public'),
    labelText: Ember['default'].computed('model.public', function () {
      return this.get('model.public') ? 'TM is public' : 'TM is private';
    }),
    filePickerText: Ember['default'].computed('notUploaded', function () {
      return this.get('notUploaded') ? 'Drag & Drop to upload' : 'Click upload button';
    }),

    actions: {
      submit: function submit() {
        var self = this;
        var onSuccess = function onSuccess() {
          self.transitionToRoute('tms.index');
        };
        var onFail = function onFail() {
          swal('Error', 'something went wrong', 'error');
          self.set('isLoading', false);
          self.set('notUploaded', true);
        };
        this.set('isLoading', true);
        return this.get('model').save().then(onSuccess, onFail);
      },

      toggleLock: function toggleLock() {
        this.toggleProperty('model.public');
      },

      fileLoaded: function fileLoaded(file) {
        var model = this.get('model');
        model.set('file', file);
        if (Ember['default'].isEmpty(model.get('name'))) {
          model.set('name', file.name);
        }
        this.set('notUploaded', false);
      }
    }
  });

});