define('cat-frontend/initializers/initialize-torii', ['exports', 'torii/bootstrap/torii', 'torii/configuration', 'cat-frontend/config/environment'], function (exports, bootstrapTorii, configuration, config) {

  'use strict';

  var initializer = {
    name: 'torii',
    initialize: function initialize(application) {
      if (arguments[1]) {
        // Ember < 2.1
        application = arguments[1];
      }
      configuration.configure(config['default'].torii || {});
      bootstrapTorii['default'](application);
      application.inject('route', 'torii', 'service:torii');
    }
  };

  exports['default'] = initializer;

});