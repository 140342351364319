define('cat-frontend/templates/profile', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 62
            },
            "end": {
              "line": 1,
              "column": 454
            }
          },
          "moduleName": "cat-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"value","Update data");
          dom.setAttribute(el1,"class","btn btn-primary");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
          morphs[3] = dom.createAttrMorph(element0, 'disabled');
          morphs[4] = dom.createElementMorph(element0);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["inline","em-input",[],["property","givenName","label","First name","placeholder","Enter first name"],["loc",[null,[1,106],[1,189]]]],
          ["inline","em-input",[],["property","familyName","label","Last name","placeholder","Enter last name"],["loc",[null,[1,189],[1,271]]]],
          ["inline","em-input",[],["property","email","label","Email","placeholder","Enter email"],["loc",[null,[1,271],[1,340]]]],
          ["attribute","disabled",["get","model.isntValid",["loc",[null,[1,378],[1,393]]]]],
          ["element","action",["submit"],[],["loc",[null,[1,347],[1,366]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 484
          }
        },
        "moduleName": "cat-frontend/templates/profile.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","container");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-md-4");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0, 0]),0,0);
        return morphs;
      },
      statements: [
        ["block","em-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[1,79],[1,84]]]]],[],[]],"submit_button",false],0,null,["loc",[null,[1,62],[1,466]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});