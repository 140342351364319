define('cat-frontend/tests/unit/models/chunk-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel('chunk', 'Unit | Model | chunk', {
    needs: ['model:document', 'model:language', 'model:user', 'model:suggestion']
  });

  ember_qunit.test('it exists', function (assert) {
    var model = this.subject();
    // var store = this.store();
    assert.ok(!!model);
  });

});