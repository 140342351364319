define('cat-frontend/tests/components/check-box.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/check-box.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'components/check-box.js should pass jshint.\ncomponents/check-box.js: line 11, col 19, \'e\' is defined but never used.\n\n1 error'); 
  });

});