define('cat-frontend/components/em-form', ['exports', 'ember-idx-forms/form'], function (exports, FormComponent) {

  'use strict';

  FormComponent['default'].reopen({
    enctype: 'multipart/form-data',
    attributeBindings: ['role', 'enctype']
  });

  exports['default'] = FormComponent['default'];

});