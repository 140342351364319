define('cat-frontend/templates/login', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1090
            },
            "end": {
              "line": 1,
              "column": 1519
            }
          },
          "moduleName": "cat-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          var el2 = dom.createElement("span");
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","line");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [0]);
          var element7 = dom.childAt(element6, [0]);
          var element8 = dom.childAt(element7, [0]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createAttrMorph(element7, 'class');
          morphs[2] = dom.createAttrMorph(element8, 'class');
          morphs[3] = dom.createMorphAt(element6,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["login__input ",["subexpr","if",[["get","invalidPasswordCheck",["loc",[null,[1,1138],[1,1158]]]],"invalid"],[],["loc",[null,[1,1133],[1,1170]]]]]]],
          ["attribute","class",["concat",["input__icon ",["subexpr","if",[["get","invalidPasswordCheck",["loc",[null,[1,1202],[1,1222]]]],"invalid"],[],["loc",[null,[1,1197],[1,1234]]]]]]],
          ["attribute","class",["concat",["fa fa-lg ",["subexpr","if",[["get","invalidPasswordCheck",["loc",[null,[1,1260],[1,1280]]]],"fa-exclamation","fa-lock"],[],["loc",[null,[1,1255],[1,1309]]]]]]],
          ["inline","input",[],["type","password","placeholder","Re-enter your password","value",["subexpr","@mut",[["get","passwordConfirmation",["loc",[null,[1,1415],[1,1435]]]]],[],[]],"focus-in","removeInvalid","class","input__credentials js-input-password-check"],["loc",[null,[1,1348],[1,1513]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1850
            },
            "end": {
              "line": 1,
              "column": 1976
            }
          },
          "moduleName": "cat-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","login__buttons");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","buttons__button");
          var el3 = dom.createTextNode("Log In");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [0, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["element","action",["login"],["on","click"],["loc",[null,[1,1901],[1,1930]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1976
            },
            "end": {
              "line": 1,
              "column": 2100
            }
          },
          "moduleName": "cat-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","login__buttons");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","buttons__button");
          var el3 = dom.createTextNode("Register");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [0, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [
          ["element","action",["register"],["on","click"],["loc",[null,[1,2020],[1,2052]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2302
            },
            "end": {
              "line": 1,
              "column": 2673
            }
          },
          "moduleName": "cat-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","login__terms");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","terms__text");
          var el3 = dom.createTextNode("By registering, you agree to the ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","terms__text_link");
          var el4 = dom.createTextNode("Terms and Conditions");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","terms__text");
          var el3 = dom.createTextNode(" and accept our ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","terms__text_link");
          var el4 = dom.createTextNode("Privacy Policy");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var element2 = dom.childAt(element1, [0, 1]);
          var element3 = dom.childAt(element1, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [
          ["element","action",["openModal","terms"],["on","click"],["loc",[null,[1,2415],[1,2456]]]],
          ["element","action",["openModal","policy"],["on","click"],["loc",[null,[1,2572],[1,2614]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 2804
              },
              "end": {
                "line": 1,
                "column": 2918
              }
            },
            "moduleName": "cat-frontend/templates/login.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createUnsafeMorphAt(element0,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["error__text ",["subexpr","if",[["get","ifOneError",["loc",[null,[1,2868],[1,2878]]]],"error__text_full"],[],["loc",[null,[1,2863],[1,2899]]]]]]],
            ["content","error",["loc",[null,[1,2901],[1,2912]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2680
            },
            "end": {
              "line": 1,
              "column": 2933
            }
          },
          "moduleName": "cat-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","login__error");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","error__exclamation");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-lg fa-exclamation");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","filteredErrors",["loc",[null,[1,2812],[1,2826]]]]],[],0,null,["loc",[null,[1,2804],[1,2927]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2946
            },
            "end": {
              "line": 1,
              "column": 3064
            }
          },
          "moduleName": "cat-frontend/templates/login.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","login-modal",[],["isModal",["subexpr","@mut",[["get","isModal",["loc",[null,[1,2983],[1,2990]]]]],[],[]],"isTerms",["subexpr","@mut",[["get","isTerms",["loc",[null,[1,2999],[1,3006]]]]],[],[]],"modalHeaderText",["subexpr","@mut",[["get","modalHeaderText",["loc",[null,[1,3023],[1,3038]]]]],[],[]],"closeModal","closeModal"],["loc",[null,[1,2961],[1,3064]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 3081
          }
        },
        "moduleName": "cat-frontend/templates/login.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","login");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","login__body");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","login__icon");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","login__content");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","login__menu");
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("Log In");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("Register");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("span");
        var el6 = dom.createElement("i");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","line");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("span");
        var el6 = dom.createElement("i");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","line");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","login__checkbox-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","login__checkbox");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","checkbox__field");
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("✔");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","checkbox__text");
        var el7 = dom.createTextNode("Show password");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","login__text");
        var el5 = dom.createTextNode("or Log In with");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","login__line");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","login__socials");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","login__facebook");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [0, 1]);
        var element11 = dom.childAt(element10, [0]);
        var element12 = dom.childAt(element11, [0]);
        var element13 = dom.childAt(element11, [1]);
        var element14 = dom.childAt(element10, [1]);
        var element15 = dom.childAt(element14, [0]);
        var element16 = dom.childAt(element15, [0]);
        var element17 = dom.childAt(element10, [2]);
        var element18 = dom.childAt(element17, [0]);
        var element19 = dom.childAt(element18, [0]);
        var element20 = dom.childAt(element10, [4, 0, 0]);
        var element21 = dom.childAt(element20, [0]);
        var element22 = dom.childAt(element10, [8, 0]);
        var morphs = new Array(21);
        morphs[0] = dom.createAttrMorph(element12, 'class');
        morphs[1] = dom.createElementMorph(element12);
        morphs[2] = dom.createAttrMorph(element13, 'class');
        morphs[3] = dom.createElementMorph(element13);
        morphs[4] = dom.createAttrMorph(element14, 'class');
        morphs[5] = dom.createAttrMorph(element15, 'class');
        morphs[6] = dom.createAttrMorph(element16, 'class');
        morphs[7] = dom.createMorphAt(element14,1,1);
        morphs[8] = dom.createAttrMorph(element17, 'class');
        morphs[9] = dom.createAttrMorph(element18, 'class');
        morphs[10] = dom.createAttrMorph(element19, 'class');
        morphs[11] = dom.createMorphAt(element17,1,1);
        morphs[12] = dom.createMorphAt(element10,3,3);
        morphs[13] = dom.createElementMorph(element20);
        morphs[14] = dom.createAttrMorph(element21, 'class');
        morphs[15] = dom.createMorphAt(element10,5,5);
        morphs[16] = dom.createElementMorph(element22);
        morphs[17] = dom.createMorphAt(element9,1,1);
        morphs[18] = dom.createMorphAt(element9,2,2);
        morphs[19] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["menu__title ",["subexpr","if",[["get","isLogin",["loc",[null,[1,199],[1,206]]]],"menu__title_active"],[],["loc",[null,[1,194],[1,229]]]]]]],
        ["element","action",["changeTab","login"],["on","click"],["loc",[null,[1,133],[1,174]]]],
        ["attribute","class",["concat",["menu__title ",["subexpr","if",[["get","isRegister",["loc",[null,[1,317],[1,327]]]],"menu__title_active"],[],["loc",[null,[1,312],[1,350]]]]]]],
        ["element","action",["changeTab","register"],["on","click"],["loc",[null,[1,248],[1,292]]]],
        ["attribute","class",["concat",["login__input ",["subexpr","if",[["get","invalidEmail",["loc",[null,[1,402],[1,414]]]],"invalid"],[],["loc",[null,[1,397],[1,426]]]]]]],
        ["attribute","class",["concat",["input__icon ",["subexpr","if",[["get","invalidEmail",["loc",[null,[1,458],[1,470]]]],"invalid"],[],["loc",[null,[1,453],[1,482]]]]]]],
        ["attribute","class",["concat",["fa fa-lg ",["subexpr","if",[["get","invalidEmail",["loc",[null,[1,508],[1,520]]]],"fa-exclamation","fa-envelope"],[],["loc",[null,[1,503],[1,553]]]]]]],
        ["inline","input",[],["type","email","placeholder","Enter your email","value",["subexpr","@mut",[["get","email",["loc",[null,[1,650],[1,655]]]]],[],[]],"focus-in","removeInvalid","class","input__credentials"],["loc",[null,[1,592],[1,709]]]],
        ["attribute","class",["concat",["login__input ",["subexpr","if",[["get","invalidPassword",["loc",[null,[1,745],[1,760]]]],"invalid"],[],["loc",[null,[1,740],[1,772]]]]]]],
        ["attribute","class",["concat",["input__icon ",["subexpr","if",[["get","invalidPassword",["loc",[null,[1,804],[1,819]]]],"invalid"],[],["loc",[null,[1,799],[1,831]]]]]]],
        ["attribute","class",["concat",["fa fa-lg ",["subexpr","if",[["get","invalidPassword",["loc",[null,[1,857],[1,872]]]],"fa-exclamation","fa-lock"],[],["loc",[null,[1,852],[1,901]]]]]]],
        ["inline","input",[],["type","password","placeholder","Enter your password","value",["subexpr","@mut",[["get","password",["loc",[null,[1,1004],[1,1012]]]]],[],[]],"focus-in","removeInvalid","class","input__credentials js-input-password"],["loc",[null,[1,940],[1,1084]]]],
        ["block","if",[["get","isRegister",["loc",[null,[1,1096],[1,1106]]]]],[],0,null,["loc",[null,[1,1090],[1,1526]]]],
        ["element","action",["toggleShowPassword"],["on","click"],["loc",[null,[1,1599],[1,1641]]]],
        ["attribute","class",["concat",["checkbox__check ",["subexpr","if",[["get","showPassword",["loc",[null,[1,1699],[1,1711]]]],"checkbox__field_checked","checkbox__field_not-checked"],[],["loc",[null,[1,1694],[1,1769]]]]]]],
        ["block","if",[["get","isLogin",["loc",[null,[1,1856],[1,1863]]]]],[],1,2,["loc",[null,[1,1850],[1,2107]]]],
        ["element","action",["facebookLogin"],["on","click"],["loc",[null,[1,2216],[1,2253]]]],
        ["block","if",[["get","isRegister",["loc",[null,[1,2308],[1,2318]]]]],[],3,null,["loc",[null,[1,2302],[1,2680]]]],
        ["block","if",[["get","errors.length",["loc",[null,[1,2686],[1,2699]]]]],[],4,null,["loc",[null,[1,2680],[1,2940]]]],
        ["block","if",[["get","isModal",["loc",[null,[1,2952],[1,2959]]]]],[],5,null,["loc",[null,[1,2946],[1,3071]]]],
        ["content","outlet",["loc",[null,[1,3071],[1,3081]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }()));

});