define('cat-frontend/controllers/documents/new', ['exports', 'ember', 'cat-frontend/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    NoInput: Ember['default'].computed('model.text', function () {
      var text = this.get('model.text');
      return !(text && text.length > 0);
    }),

    isLoading: false,

    documentStateChanged: Ember['default'].observer('model.state', function () {
      var document = this.get('model');
      var state = this.get('model.state');
      console.info('documents/new: state changed: \'' + state + '\'');

      switch (state) {
        case 'converted':
          this.set('isLoading', false);
          this.transitionToRoute('document', document);
          this.set('model', null);
          break;

        case 'failed':
          this.set('isLoading', false);
          swal('Error', 'an error #5 occurred, please try again later', 'error');
          this.set('model', this.store.createRecord('document', {}));
          break;
      }
    }),

    actions: {
      prepareText: function prepareText() {
        var file = {};
        var model = this.get('model');
        var str = btoa(unescape(encodeURIComponent(this.get('model.text'))));

        file.type = 'text/plain';
        file.filename = this.get('model.title') || 'default.txt';
        file.data = 'data:text/plain;base64,' + str;
        file.size = str.length * 3 / 4;

        model.set('file', file);
        if (Ember['default'].isEmpty(model.get('title'))) {
          model.set('title', file.filename);
        }
        this.send('submit');
      },
      submit: function submit() {
        var _this = this;

        this.set('isLoading', true);

        var onSuccess = function onSuccess(document) {
          // Set timer and on finish check if state is not 'converted' yet, then
          var timeout = ENV['default'].constants.pollCycles * ENV['default'].constants.pollTimeOut;
          Ember['default'].run.later(function () {
            if (_this.get('isLoading')) {
              _this.set('isLoading', false);
              if (document.get('state') !== 'converted') {
                console.error('Document is still Loading after ' + timeout + ', it’s state is \'' + document.get('state') + '\'');
                swal('Error', 'an error #6 occurred, please try again later', 'error');
                _this.set('model', _this.store.createRecord('document', {}));
              } else {
                _this.transitionToRoute('document', document);
              }
            }
          }, timeout);
        };

        var onFail = function onFail() {
          _this.set('isLoading', false);
          swal('Error', 'an error #7 occurred, please try again later', 'error');
          console.error('unsuccessfully uploaded document');
        };

        return this.get('model').save().then(onSuccess, onFail);
      },
      fileLoaded: function fileLoaded(file) {
        var allowedTypes = ['application/pdf', 'text/plain', 'text/html', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
        var model = this.get('model');
        if (allowedTypes.indexOf(file.type) !== -1) {
          model.set('file', file);
          if (Ember['default'].isEmpty(model.get('title'))) {
            model.set('title', file.filename);
          }

          return this.send('submit');
        } else {
          swal('Error', 'document type not supported', 'error');
        }
      }
    }
  });

});