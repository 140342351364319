define('cat-frontend/torii-providers/skuuper-oauth2', ['exports', 'torii/configuration', 'torii/providers/oauth2-bearer', 'cat-frontend/config/environment'], function (exports, configuration, Oauth2Bearer, ENV) {

  'use strict';

  var SkuuperOAuth2 = Oauth2Bearer['default'].extend({
    name: 'skuuper-oauth2',
    baseUrl: ENV['default'].torii.providers['skuuper-oauth2'].baseUrl,

    responseParams: ['access_token'],

    redirectUri: configuration.configurable('redirectUri', function () {
      // A hack that allows redirectUri to be configurable
      // but default to the superclass
      return this._super();
    }),

    open: function open() {
      'use strict';
      var url = this.buildUrl();
      var responseParams = this.get('responseParams');

      return this.get('popup').open(url, responseParams).then(function (authData) {
        var missingResponseParams = [];
        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });

        if (missingResponseParams.length) {
          throw 'The response from the provider is missing ' + 'these required response params: ' + responseParams.join(', ');
        }
        return authData;
      });
    },

    fetch: function fetch(authData) {
      'use strict';
      return authData;
    }
  });

  exports['default'] = SkuuperOAuth2;

});