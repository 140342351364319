define('cat-frontend/components/x-chunk', ['exports', 'ember', 'ember-autosave'], function (exports, Ember, ember_autosave) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    className: 'x-chunk',
    classNameBindings: ['translating', 'isntTranslated'],
    model: ember_autosave.computedAutosave('chunk'),
    value: Ember['default'].computed.alias('model.text'),
    isOriginal: Ember['default'].computed.alias('model.isOriginal'),
    isTranslation: Ember['default'].computed.not('isOriginal'),
    translating: Ember['default'].computed.alias('model.translating'),

    isntTranslated: Ember['default'].computed('isTranslation', 'value', 'model.original.text', function () {
      return this.get('isTranslation') && this.get('value') === this.get('model.original.text');
    }),
    isTranslated: Ember['default'].computed.not('isntTranslated'),

    editable: Ember['default'].computed('chunk.isLoaded', 'isTranslation', 'value', 'model.children', function () {
      if (this.get('chunk.isLoaded') && Ember['default'].isEmpty(this.get('value')) && this.get('isTranslation')) {
        this.set('value', this.get('model.original.text'));
      }
      return this.get('chunk.isLoaded') && this.get('isTranslation') && Ember['default'].isEmpty(this.get('model.children'));
    }),

    valueDidChange: Ember['default'].observer('chunk.text', 'isOriginal', function () {
      if (!this.get('isOriginal') && Ember['default'].isEmpty(this.get('chunk.text'))) {
        this.set('chunk.text', this.get('chunk.original.text'));
      }
    }),

    click: function click() {
      if (this.get('model.translation.text') && !window.selected) {
        this.set('model.translation.focused', true);
      }
    }
  });

});