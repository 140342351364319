define('cat-frontend/components/chunks-editor-suggestions-box', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['suggestions'],
    classNameBindings: ['hidden:suggestions_hidden:suggestions_not-hidden'],

    hidden: true,

    didInsertElement: function didInsertElement() {
      Ember['default'].run.later(this, function () {
        this.set('hidden', false);
      }, 10);
    },

    mouseDown: function mouseDown() {
      //do not let focusOut event occur at editor
      return false;
    }

  });

});