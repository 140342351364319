define('cat-frontend/templates/components/document-properties', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 35
            },
            "end": {
              "line": 1,
              "column": 168
            }
          },
          "moduleName": "cat-frontend/templates/components/document-properties.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","b-document-properties__progress");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","radial-progress",[],["number",["subexpr","@mut",[["get","model.stageProgress",["loc",[null,[1,123],[1,142]]]]],[],[]],"stage",["subexpr","@mut",[["get","model.stage",["loc",[null,[1,149],[1,160]]]]],[],[]]],["loc",[null,[1,98],[1,162]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 168
              },
              "end": {
                "line": 1,
                "column": 1557
              }
            },
            "moduleName": "cat-frontend/templates/components/document-properties.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","b-document-properties__header");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","header__icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","header__title");
            var el3 = dom.createTextNode("Translate");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1,"class","form-center form-horizontal");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-group");
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","form-text control-label col-sm-2");
            var el4 = dom.createTextNode("From:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-sm-10");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-group");
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","form-text control-label col-sm-2");
            var el4 = dom.createTextNode("To:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-sm-10");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-group");
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","microsoft-translator-disclaimer form-text checkbox-form-label col-sm-offset-2 col-sm-8");
            var el4 = dom.createElement("p");
            var el5 = dom.createTextNode("Use Microsoft Translator");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            var el5 = dom.createTextNode("Microsoft Translator API will be used to assist with translation. Special conditions may apply. ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("a");
            dom.setAttribute(el5,"href","https://translator.microsoft.com/help/");
            dom.setAttribute(el5,"target","_blank");
            var el6 = dom.createTextNode("Learn more.");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-sm-2");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","b-document-properties__button-container");
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","form-btn b-document-properties__button");
            var el3 = dom.createTextNode("NEXT");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [2]);
            var element7 = dom.childAt(element6, [0, 0]);
            var element8 = dom.childAt(fragment, [2, 0]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [0, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
            morphs[2] = dom.createElementMorph(element7);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
            morphs[4] = dom.createAttrMorph(element8, 'disabled');
            morphs[5] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [
            ["inline","l-select",[],["class","form-control form-select","options",["subexpr","@mut",[["get","languages",["loc",[null,[1,514],[1,523]]]]],[],[]],"field","name","selected",["subexpr","@mut",[["get","model.language",["loc",[null,[1,546],[1,560]]]]],[],[]],"defaultTitle","Select Language"],["loc",[null,[1,462],[1,593]]]],
            ["inline","l-select",[],["class","form-control form-select","options",["subexpr","@mut",[["get","languages",["loc",[null,[1,763],[1,772]]]]],[],[]],"field","name","selected",["subexpr","@mut",[["get","model.translateTo",["loc",[null,[1,795],[1,812]]]]],[],[]],"defaultTitle","Select Language"],["loc",[null,[1,711],[1,845]]]],
            ["element","action",["toggleMachineTranslation"],["on","click"],["loc",[null,[1,986],[1,1034]]]],
            ["inline","check-box",[],["checked",["subexpr","@mut",[["get","model.useMt",["loc",[null,[1,1302],[1,1313]]]]],[],[]],"class","checkbox-form checkbox"],["loc",[null,[1,1282],[1,1346]]]],
            ["attribute","disabled",["get","nextDisabled",["loc",[null,[1,1476],[1,1488]]]]],
            ["element","action",["nextStep",["get","model",["loc",[null,[1,1446],[1,1451]]]]],["on","click"],["loc",[null,[1,1426],[1,1464]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1557
              },
              "end": {
                "line": 1,
                "column": 2924
              }
            },
            "moduleName": "cat-frontend/templates/components/document-properties.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","b-document-properties__header");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","header__icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","header__title");
            var el3 = dom.createTextNode("Document Properties");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1,"class","form-center form-horizontal");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-group");
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","form-text control-label col-sm-2");
            var el4 = dom.createTextNode("Name:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-sm-10");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-group");
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","move-24-left form-text control-label col-sm-2");
            var el4 = dom.createTextNode("Timestamp:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-sm-10");
            var el4 = dom.createElement("input");
            dom.setAttribute(el4,"readonly","true");
            dom.setAttribute(el4,"class","title__input cursor-disabled");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","b-document-properties__header");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","header__title");
            var el3 = dom.createTextNode("Data Tagging");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1,"class","form-center form-horizontal");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-group");
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","form-text control-label col-sm-2");
            var el4 = dom.createTextNode("Topics:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-sm-10");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","b-document-properties__button-container");
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","background-color-bluetint120 form-btn b-document-properties__button");
            var el3 = dom.createTextNode("BACK");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","form-btn b-document-properties__button");
            var el3 = dom.createTextNode("START");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1, 1, 0]);
            var element2 = dom.childAt(fragment, [4]);
            var element3 = dom.childAt(element2, [0]);
            var element4 = dom.childAt(element2, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [0, 1]),0,0);
            morphs[1] = dom.createAttrMorph(element1, 'value');
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3, 0, 1]),0,0);
            morphs[3] = dom.createAttrMorph(element3, 'disabled');
            morphs[4] = dom.createElementMorph(element3);
            morphs[5] = dom.createAttrMorph(element4, 'disabled');
            morphs[6] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [
            ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","model.title",["loc",[null,[1,1874],[1,1885]]]]],[],[]],"class","title__input"],["loc",[null,[1,1848],[1,1908]]]],
            ["attribute","value",["get","timestamp",["loc",[null,[1,2061],[1,2070]]]]],
            ["inline","multi-select",[],["class","form-control form-select","options",["subexpr","@mut",[["get","topics",["loc",[null,[1,2447],[1,2453]]]]],[],[]],"field","name","isSelectedField","isSelected","defaultTitle","Select Topics"],["loc",[null,[1,2391],[1,2526]]]],
            ["attribute","disabled",["get","nextDisabled",["loc",[null,[1,2660],[1,2672]]]]],
            ["element","action",["previousStep",["get","model",["loc",[null,[1,2630],[1,2635]]]]],["on","click"],["loc",[null,[1,2606],[1,2648]]]],
            ["attribute","disabled",["get","startTranslationDisabled",["loc",[null,[1,2830],[1,2854]]]]],
            ["element","action",["startTranslation",["get","model",["loc",[null,[1,2800],[1,2805]]]]],["on","click"],["loc",[null,[1,2772],[1,2818]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 168
            },
            "end": {
              "line": 1,
              "column": 2924
            }
          },
          "moduleName": "cat-frontend/templates/components/document-properties.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","firstStep",["loc",[null,[1,178],[1,187]]]]],[],0,1,["loc",[null,[1,168],[1,2924]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 2937
          }
        },
        "moduleName": "cat-frontend/templates/components/document-properties.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","b-document-properties");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showRadial",["loc",[null,[1,41],[1,51]]]]],[],0,1,["loc",[null,[1,35],[1,2931]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});