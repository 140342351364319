define('cat-frontend/models/language', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    code: DS['default'].attr('string'),
    documents: DS['default'].hasMany('document', {
      inverse: 'language',
      async: true
    }),
    chunks: DS['default'].hasMany('chunk', {
      inverse: 'language',
      async: true
    }),

    flagClass: Ember['default'].computed('code', function () {
      return 'flag-icon-' + this.get('code');
    })
  });

});