define('cat-frontend/tests/serializers/document.jshint', function () {

  'use strict';

  QUnit.module('JSHint - serializers');
  QUnit.test('serializers/document.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'serializers/document.js should pass jshint.'); 
  });

});