define('cat-frontend/tests/helpers/destroy-app', ['exports', 'ember'], function (exports, Ember) {

  'use strict';



  exports['default'] = destroyApp;
  function destroyApp(application) {
    Ember['default'].run(application, 'destroy');
  }

});