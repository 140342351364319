define('cat-frontend/instance-initializers/csrf', ['exports', 'ic-ajax', 'cat-frontend/config/environment'], function (exports, ic_ajax, config) {

  'use strict';

  exports.initialize = initialize;

  function initialize(application) {
    var csrf = application.container.lookup('service:csrf');
    ic_ajax.request(config['default'].APP.csrfUrl).then(function (response) {
      return csrf.setData(response);
    });
  }

  exports['default'] = {
    name: 'csrf',
    initialize: initialize
  };

});