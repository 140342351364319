define('cat-frontend/tests/mixins/click-outside.jshint', function () {

  'use strict';

  QUnit.module('JSHint - mixins');
  QUnit.test('mixins/click-outside.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'mixins/click-outside.js should pass jshint.\nmixins/click-outside.js: line 8, col 19, \'$\' is not defined.\n\n1 error'); 
  });

});