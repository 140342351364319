define('cat-frontend/templates/document', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 1128
            }
          },
          "moduleName": "cat-frontend/templates/document.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","b-document__wrapper");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","b-document__original");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","b-document__center-space_wrapper js-center-space");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","b-document__center-space");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","b-document__translation");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","help-container white-bg");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","help-container__content");
          var el5 = dom.createElement("i");
          dom.setAttribute(el5,"class","fa help-container__icon");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","help-container__text");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","fa help-container__close");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","clear");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1, 0]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [2]),1,1);
          morphs[3] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","chunks-editor",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[1,140],[1,145]]]]],[],[]],"page",["subexpr","@mut",[["get","page",["loc",[null,[1,151],[1,155]]]]],[],[]],"isConverted",["subexpr","@mut",[["get","model.isConverted",["loc",[null,[1,168],[1,185]]]]],[],[]],"label","From","isValid",["subexpr","@mut",[["get","isValid",["loc",[null,[1,207],[1,214]]]]],[],[]],"scrollHeight",["subexpr","@mut",[["get","originalScrollHeight",["loc",[null,[1,228],[1,248]]]]],[],[]],"editorScrolled",["subexpr","@mut",[["get","editorScrolled",["loc",[null,[1,264],[1,278]]]]],[],[]],"max",["subexpr","@mut",[["get","scrollHeightValue",["loc",[null,[1,283],[1,300]]]]],[],[]],"concordance","concordance"],["loc",[null,[1,118],[1,328]]]],
          ["inline","vertical-slider",[],["scrollHeight",["subexpr","@mut",[["get","scrollHeightValue",["loc",[null,[1,465],[1,482]]]]],[],[]],"scrolled",["subexpr","@mut",[["get","scrolled",["loc",[null,[1,492],[1,500]]]]],[],[]]],["loc",[null,[1,434],[1,502]]]],
          ["inline","chunks-editor",[],["model",["subexpr","@mut",[["get","model.translation",["loc",[null,[1,805],[1,822]]]]],[],[]],"page",["subexpr","@mut",[["get","page",["loc",[null,[1,828],[1,832]]]]],[],[]],"isConverted",["subexpr","@mut",[["get","model.isConverted",["loc",[null,[1,845],[1,862]]]]],[],[]],"label","To","isValid",["subexpr","@mut",[["get","isValid",["loc",[null,[1,882],[1,889]]]]],[],[]],"startTranslation",["subexpr","@mut",[["get","startTranslation",["loc",[null,[1,907],[1,923]]]]],[],[]],"original",["subexpr","@mut",[["get","model",["loc",[null,[1,933],[1,938]]]]],[],[]],"scrollHeight",["subexpr","@mut",[["get","translatedScrollHeight",["loc",[null,[1,952],[1,974]]]]],[],[]],"editorScrolled",["subexpr","@mut",[["get","editorScrolled",["loc",[null,[1,990],[1,1004]]]]],[],[]],"max",["subexpr","@mut",[["get","scrollHeightValue",["loc",[null,[1,1009],[1,1026]]]]],[],[]]],["loc",[null,[1,783],[1,1028]]]],
          ["inline","documents-pagination",[],["pagesNumber",["subexpr","@mut",[["get","model.pagesNumber",["loc",[null,[1,1075],[1,1092]]]]],[],[]],"itemsPerPage",100,"currentPage",["subexpr","@mut",[["get","page",["loc",[null,[1,1122],[1,1126]]]]],[],[]]],["loc",[null,[1,1040],[1,1128]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1128
              },
              "end": {
                "line": 1,
                "column": 1300
              }
            },
            "moduleName": "cat-frontend/templates/document.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","document-properties",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[1,1217],[1,1222]]]]],[],[]],"languages",["subexpr","@mut",[["get","languages",["loc",[null,[1,1233],[1,1242]]]]],[],[]],"translateToLanguages",["subexpr","@mut",[["get","translateToLanguages",["loc",[null,[1,1264],[1,1284]]]]],[],[]],"topics",["subexpr","@mut",[["get","topics",["loc",[null,[1,1292],[1,1298]]]]],[],[]]],["loc",[null,[1,1189],[1,1300]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1128
            },
            "end": {
              "line": 1,
              "column": 1300
            }
          },
          "moduleName": "cat-frontend/templates/document.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["get","model.isConverted",["loc",[null,[1,1142],[1,1159]]]],["get","model.isMachineTranslation",["loc",[null,[1,1160],[1,1186]]]]],[],["loc",[null,[1,1138],[1,1187]]]]],[],0,null,["loc",[null,[1,1128],[1,1300]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1358
              },
              "end": {
                "line": 1,
                "column": 1608
              }
            },
            "moduleName": "cat-frontend/templates/document.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","chunks-editor-suggestions-box",[],["suggestions",["subexpr","@mut",[["get","suggestions",["loc",[null,[1,1423],[1,1434]]]]],[],[]],"translatedChunk",["subexpr","@mut",[["get","translatedChunk",["loc",[null,[1,1451],[1,1466]]]]],[],[]],"wordForConcordance",["subexpr","@mut",[["get","wordForConcordance",["loc",[null,[1,1486],[1,1504]]]]],[],[]],"concordanceSuggestions",["subexpr","@mut",[["get","concordanceSuggestions",["loc",[null,[1,1528],[1,1550]]]]],[],[]],"showBottomBox",["subexpr","@mut",[["get","showBottomBox",["loc",[null,[1,1565],[1,1578]]]]],[],[]],"showError",["subexpr","@mut",[["get","noConcordanceData",["loc",[null,[1,1589],[1,1606]]]]],[],[]]],["loc",[null,[1,1379],[1,1608]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1307
            },
            "end": {
              "line": 1,
              "column": 1615
            }
          },
          "moduleName": "cat-frontend/templates/document.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showBottomBox",["loc",[null,[1,1364],[1,1377]]]]],[],0,null,["loc",[null,[1,1358],[1,1615]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1632
          }
        },
        "moduleName": "cat-frontend/templates/document.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","or",[["get","model.isTranslating",["loc",[null,[1,10],[1,29]]]],["get","model.isTranslated",["loc",[null,[1,30],[1,48]]]]],[],["loc",[null,[1,6],[1,49]]]]],[],0,1,["loc",[null,[1,0],[1,1307]]]],
        ["block","if",[["subexpr","or",[["get","model.isTranslating",["loc",[null,[1,1317],[1,1336]]]],["get","model.isTranslated",["loc",[null,[1,1337],[1,1355]]]]],[],["loc",[null,[1,1313],[1,1356]]]]],[],2,null,["loc",[null,[1,1307],[1,1622]]]],
        ["content","outlet",["loc",[null,[1,1622],[1,1632]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});