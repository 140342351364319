define('cat-frontend/components/drop-popup', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      Ember['default'].run.schedule('afterRender', this, function () {
        this.set("register-as", this);
      });

      var $el = Ember['default'].$('.ember-modal-dialog-target-attachment-bottom');
      var content = Ember['default'].$('.js-popup-content').children();
      $el.css({ position: "absolute", visibility: "visible", display: "block" });
      var height = content.outerHeight();
      $el.css({ position: "", visibility: "", display: "" });

      Ember['default'].run.next(this, function () {
        $el.show();
        Ember['default'].run.later(this, function () {
          $el.addClass('ember-modal-dialog-target-attachment-bottom-visible').height(height);
          content.show();
          Ember['default'].run.later(this, function () {
            content.css({ opacity: 1 });
          }, 100);
        }, 100);
      });
    },

    toggle: function toggle() {
      var $el = Ember['default'].$('.ember-modal-dialog-target-attachment-bottom');
      var content = Ember['default'].$('.js-popup-content').children();
      Ember['default'].run.next(this, function () {
        content.css({ opacity: 0 });
        Ember['default'].run.later(this, function () {
          content.remove();
          $el.toggleClass('ember-modal-dialog-target-attachment-bottom-visible').height(0);
          Ember['default'].run.later(this, function () {
            this.sendAction('closePopup');
          }, 200);
        }, 200);
      });
    },

    actions: {
      togglePopup: function togglePopup() {
        this.toggle();
      }
    }

  });

});