define('cat-frontend/models/topic', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var topic = DS['default'].Model.extend({
    name: DS['default'].attr('string'),

    document: DS['default'].belongsTo('document', { inverse: 'topics', async: true }),

    isSelected: false,
    toggleSelected: function toggleSelected(document) {
      this.set('isSelected', !this.get('isSelected'));
      this.set('document', document);
    }

  });

  exports['default'] = topic;

});