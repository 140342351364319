define('cat-frontend/tests/authenticators/devise.jshint', function () {

  'use strict';

  QUnit.module('JSHint - authenticators');
  QUnit.test('authenticators/devise.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'authenticators/devise.js should pass jshint.'); 
  });

});