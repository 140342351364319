define('cat-frontend/tests/unit/components/em-form-test.jshint', function () {

  'use strict';

  QUnit.module('JSHint - unit/components');
  QUnit.test('unit/components/em-form-test.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'unit/components/em-form-test.js should pass jshint.'); 
  });

});