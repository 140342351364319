define('cat-frontend/controllers/profile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    actions: {
      submit: function submit() {
        swal("Success", "Data has been saved", "success");
        this.get('model').save();
      }
    }
  });

});