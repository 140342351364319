define('cat-frontend/tests/components/editable-select.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/editable-select.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'components/editable-select.js should pass jshint.\ncomponents/editable-select.js: line 18, col 75, Missing semicolon.\ncomponents/editable-select.js: line 31, col 40, Missing semicolon.\ncomponents/editable-select.js: line 32, col 88, Missing semicolon.\ncomponents/editable-select.js: line 32, col 9, \'selectedValue\' is defined but never used.\ncomponents/editable-select.js: line 44, col 19, \'e\' is defined but never used.\ncomponents/editable-select.js: line 77, col 31, Expected \'===\' and instead saw \'==\'.\ncomponents/editable-select.js: line 92, col 29, Expected \'!==\' and instead saw \'!=\'.\n\n7 errors'); 
  });

});