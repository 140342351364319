define('cat-frontend/models/user', ['exports', 'ember-data', 'ember', 'ember-validations'], function (exports, DS, Ember, EmberValidations) {

  'use strict';

  exports['default'] = (function () {
    var User = DS['default'].Model.extend(EmberValidations['default'], {
      givenName: DS['default'].attr('string'),
      familyName: DS['default'].attr('string'),
      avatarUrl: DS['default'].attr('string'),
      email: DS['default'].attr('string'),
      documents: DS['default'].hasMany('document', { inverse: 'owner', async: true }),
      isntValid: Ember['default'].computed.not('isValid'),

      fullName: Ember['default'].computed('givenName', 'familyName', function () {
        return "%@1 %@2".fmt(this.get('givenName'), this.get('familyName'));
      })
    });

    User.reopen({
      validations: {
        givenName: {
          presence: true
        },
        familyName: {
          presence: true
        },
        email: {
          presence: true,
          format: {
            'with': /^.+@.+\..+$/,
            message: 'must be valid email'
          }
        }
      }
    });

    return User;
  })();

});