define('cat-frontend/controllers/login', ['exports', 'ember', 'ic-ajax', 'cat-frontend/config/environment'], function (exports, Ember, ic_ajax, config) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    session: Ember['default'].inject.service('session'),

    showPassword: false,
    rememberMe: false,
    activeMenu: 'login',
    modal: 'terms',

    isLogin: Ember['default'].computed.equal('activeMenu', 'login'),
    isRegister: Ember['default'].computed.equal('activeMenu', 'register'),
    isModal: false,

    invalidEmail: false,
    invalidPassword: false,
    invalidPasswordCheck: false,
    errors: [],

    ifOneError: Ember['default'].computed.equal('errors.length', 1),
    filteredErrors: Ember['default'].computed('errors', function () {
      var errors = this.get('errors');
      if (errors.get('length') > 1) {
        return errors.map(function (item) {
          return '- ' + item;
        });
      }
      return errors;
    }),
    modalHeaderText: Ember['default'].computed('modal', function () {
      var modal = this.get('modal');
      if (modal === 'terms') {
        return 'Terms and Conditions';
      }
      if (modal === 'policy') {
        return 'Privacy policy';
      }
    }),
    isTerms: Ember['default'].computed.equal('modal', 'terms'),

    observerShowPassword: Ember['default'].observer('showPassword', 'activeMenu', function () {
      Ember['default'].run.schedule('afterRender', this, function () {
        Ember['default'].$('.js-input-password')[0].type = this.get('showPassword') ? 'text' : 'password';
        var input = Ember['default'].$('.js-input-password-check');
        if (input[0]) {
          input[0].type = this.get('showPassword') ? 'text' : 'password';
        }
      });
    }),

    validate: function validate(data) {
      var valid = true;
      var errors = this.get('errors');

      if (!data.email) {
        this.set('invalidEmail', true);
        errors.pushObject(config['default'].validationErrors.noEmail);
        valid = false;
      }
      if (!data.password) {
        this.set('invalidPassword', true);
        errors.pushObject(config['default'].validationErrors.noPassword);
        valid = false;
      }
      if (data.passwordConfirmation && data.passwordConfirmation !== data.password) {
        this.set('invalidPasswordCheck', true);
        errors.pushObject(config['default'].validationErrors.dontMatch);
        valid = false;
      }

      return valid;
    },

    actions: {
      authenticate: function authenticate(data) {
        var _this = this;

        this.get('session').authenticate('authenticator:devise', data.email, data.password)['catch'](function (error) {
          _this.get('errors').pushObject(error.error);
          _this.setProperties({
            invalidEmail: true,
            invalidPassword: true
          });
        });
      },

      facebookLogin: function facebookLogin() {
        this.get('session').authenticate('authenticator:torii', 'facebook');
      },

      toggleInvalid: function toggleInvalid() {
        this.set('invalidEmail', !this.get('invalidEmail'));
      },

      removeInvalid: function removeInvalid() {
        this.setProperties({
          invalidEmail: false,
          invalidPassword: false,
          invalidPasswordCheck: false,
          errors: []
        });
      },

      register: function register() {
        var _this2 = this;

        this.send('removeInvalid');

        var data = this.getProperties('email', 'password', 'passwordConfirmation');
        var errors = this.get('errors');
        data.password_confirmation = data.passwordConfirmation;

        if (!data.passwordConfirmation) {
          this.validate(data);
          this.set('invalidPasswordCheck', true);
        } else {
          if (this.validate(data)) {
            delete data.passwordConfirmation;

            return ic_ajax.request(config['default'].APP.apiURL + '/users.json', {
              method: "POST",
              dataType: 'json',
              data: {
                user: data
              }
            }).then(function () {
              _this2.send('authenticate', data);
            }, function (error) {
              var errorObj = error.jqXHR.responseJSON.errors;
              if (errorObj.hasOwnProperty('email')) {
                errorObj.email.forEach(function (item) {
                  errors.pushObject('Email ' + item);
                });
                _this2.set('invalidEmail', true);
              }
              if (errorObj.hasOwnProperty('password')) {
                errorObj.password.forEach(function (item) {
                  errors.pushObject('Password ' + item);
                });
                _this2.set('invalidPassword', true);
              }
            });
          }
        }
      },

      login: function login() {
        var data = this.getProperties('email', 'password');
        this.send('removeInvalid');

        if (this.validate(data)) {
          this.send('authenticate', data);
        }
      },

      changeTab: function changeTab(menu) {
        this.send('removeInvalid');
        this.set('activeMenu', menu);
      },

      toggleShowPassword: function toggleShowPassword() {
        this.set('showPassword', !this.get('showPassword'));
      },

      toggleRememberMe: function toggleRememberMe() {
        this.set('rememberMe', !this.get('rememberMe'));
      },

      openModal: function openModal(data) {
        this.set('isModal', true);
        this.set('modal', data);
      },
      closeModal: function closeModal() {
        this.set('isModal', false);
      }
    }
  });

});