define('cat-frontend/tests/models/chunk.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models');
  QUnit.test('models/chunk.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'models/chunk.js should pass jshint.'); 
  });

});