define('cat-frontend/components/editable-select', ['exports', 'ember', 'cat-frontend/mixins/click-outside'], function (exports, Ember, ClickOutside) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(ClickOutside['default'], {
    tagName: 'span',
    selectOpened: false,
    classNameBindings: ['isOpened:form-select-opened', 'editing:form-multi-select-editing'],
    editing: false,

    isOpened: (function () {
      return this.get('selectOpened');
    }).property('selectOpened'),

    selectedValue: Ember['default'].computed('selected', function () {
      var field = this.get('field');
      var selected = this.get('selected');

      return selected ? field ? selected.get(field) : selected : undefined;
    }),

    title: Ember['default'].computed('selectedValue', 'selectOpened', function () {
      var defaultTitle = this.get('defaultTitle');
      var selectedValue = this.get('selectedValue');
      var selectOpened = this.get('selectOpened');

      return selectOpened ? defaultTitle : selectedValue ? selectedValue : defaultTitle;
    }),

    arrangedOptions: Ember['default'].computed('options.[]', 'selected', function () {
      var field = this.get('field');
      var selected = this.get('selected');
      var selectedValue = selected ? field ? selected.get(field) : selected : undefined;

      return this.get('options').map(function (item) {
        var value = field ? item.get(field) : item;

        return {
          object: item,
          value: value
        };
      });
    }),

    click: function click(e) {
      this.toggleProperty('selectOpened');
    },

    onOutsideClick: function onOutsideClick() {
      if (this.get('selectOpened')) {
        this.toggleProperty('selectOpened');
      }
    },

    actions: {
      changeOption: function changeOption(option) {
        this.set('selected', option.object);
      },

      addOption: function addOption() {
        this.get('options').pushObject(undefined);
        this.set('oldSelected', this.get('selected'));
        this.set('selected', undefined);
        this.set('editing', true);
      },

      editOption: function editOption(option) {
        this.set('oldSelected', this.get('selected'));
        this.set('selected', option.object);
        this.set('editing', true);
      },

      saveEdit: function saveEdit() {
        var oldSelected = this.get('selected');
        var inputValue = this.element.firstElementChild.firstElementChild.value;

        var newOptions = this.get('options').map(function (item) {
          return oldSelected == item ? inputValue : item;
        });

        this.set('options', newOptions);

        this.set('selected', inputValue);
        this.set('editing', false);
      },

      cancelEdit: function cancelEdit() {
        var oldSelected = this.get('oldSelected');
        var selected = this.get('selected');

        if (!selected) {
          var newOptions = this.get('options').filter(function (item) {
            return selected != item;
          });

          this.set('options', newOptions);
        }
        this.set('selected', oldSelected);

        this.set('editing', false);
        this.set('selectOpened', true);
      }
    }

  });

});