define('cat-frontend/authenticators/torii', ['exports', 'ember', 'ember-simple-auth/authenticators/torii', 'ic-ajax', 'cat-frontend/config/environment'], function (exports, Ember, Torii, raw, config) {

  'use strict';

  var RSVP = Ember['default'].RSVP;
  var service = Ember['default'].inject.service;

  exports['default'] = Torii['default'].extend({
    torii: service('torii'),

    authenticate: function authenticate() {
      var _this = this,
          _arguments = arguments;

      return new RSVP.Promise(function (resolve, reject) {
        _this._super.apply(_this, _arguments).then(function (data) {

          raw['default']({
            url: config['default'].APP.apiURL + '/users/social_sign_in.json',
            type: 'GET',
            dataType: 'json',
            data: {
              'code': data.authorizationCode,
              'provider': 'facebook'
            }
          }).then(function (response) {
            resolve({
              token: response.token,
              email: response.email,
              provider: data.provider
            });
          }, reject);
        }, reject);
      });
    }
  });

});