define('cat-frontend/templates/components/document-table-header', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 158
            }
          },
          "moduleName": "cat-frontend/templates/components/document-table-header.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1,"id","icon");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element0, 'class');
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","header",["loc",[null,[1,46],[1,56]]]],
          ["attribute","class",["concat",["documents-table__header_caret fa ",["subexpr","if",[["get","isDown",["loc",[null,[1,114],[1,120]]]],"fa-caret-down","fa-caret-up"],[],["loc",[null,[1,109],[1,152]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 170
          }
        },
        "moduleName": "cat-frontend/templates/components/document-table-header.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","link-to",[["get","link",["loc",[null,[1,11],[1,15]]]],["subexpr","query-params",[],["sort",["get","sortLink",["loc",[null,[1,35],[1,43]]]]],["loc",[null,[1,16],[1,44]]]]],[],0,null,["loc",[null,[1,0],[1,170]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});