define('cat-frontend/routes/document', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'cat-frontend/config/environment'], function (exports, Ember, AuthenticatedRouteMixin, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model(params) {
      var page = params.page;
      var store = this.store;

      return this.store.findRecord('document', params.document_id).then(function (doc) {
        doc.set('query-params', { page: page });
        return doc;
      });
    },
    afterModel: function afterModel(model) {
      if (model.get('isConverting') || model.get('isFailed')) {
        this.transitionTo('documents.new');
      }

      if (!model.get('id')) {
        return;
      }

      if (!model.get('query-params')) {
        model.set('query-params', { page: 1 });
      }
      var page = model.get('query-params').page;
      var store = this.store;
      var host = config['default'].APP.apiURL;

      //  original document chunks
      var token = this.get('session.data.authenticated.token');
      var email = this.get('session.data.authenticated.email');
      var auth_token = 'Token token="%@1", email="%@2"'.fmt(token, email);
      Ember['default'].$.ajax({
        type: 'GET',
        url: "%@1/api/documents/%@2/chunks?page=%@3".fmt(host, model.get('id'), page),
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", auth_token);
        },
        success: function success(data) {
          store.pushPayload(data);
        }
      });

      //  translated document chunks
      if (!model.get('translation.id')) {
        return;
      }
      Ember['default'].$.ajax({
        type: 'GET',
        url: "%@1/api/documents/%@2/chunks?page=%@3".fmt(host, model.get('translation.id'), page),
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", auth_token);
        },
        success: function success(data) {
          store.pushPayload(data);
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var data = this.store.peekAll('language');
      var topics = this.store.findAll('topic', { reload: true });
      topics.then(function (data) {
        data.forEach(function (item) {
          item.isSelected = false;
        });
      });
      if (!data.get('length')) {
        data = this.store.findAll('language');
      }
      controller.set('topics', topics);
      controller.set('languages', data);
      controller.set('focus', false);
      this.controllerFor('application').set('model', model);
    },
    actions: {
      gotoDocumentsList: function gotoDocumentsList() {
        return this.transitionTo('documents');
      }
    },
    queryParams: {
      page: {
        refreshModel: true
      }
    }
  });

});