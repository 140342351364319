define('cat-frontend/instance-initializers/faye', ['exports'], function (exports) {

  'use strict';

  exports.initialize = initialize;

  function initialize(applicationInstance) {
    var faye = applicationInstance.container.lookup('service:faye');
    console.debug('Faye Service:', faye);
  }

  exports['default'] = {
    name: 'faye',
    initialize: initialize
  };

});