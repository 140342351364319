define('cat-frontend/tests/torii-providers/skuuper-oauth2.jshint', function () {

  'use strict';

  QUnit.module('JSHint - torii-providers');
  QUnit.test('torii-providers/skuuper-oauth2.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'torii-providers/skuuper-oauth2.js should pass jshint.'); 
  });

});