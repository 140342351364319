define('cat-frontend/tests/models/document.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models');
  QUnit.test('models/document.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'models/document.js should pass jshint.\nmodels/document.js: line 4, col 8, \'config\' is defined but never used.\n\n1 error'); 
  });

});