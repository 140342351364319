define('cat-frontend/tests/unit/helpers/document-stage-test', ['cat-frontend/helpers/document-stage', 'qunit'], function (document_stage, qunit) {

  'use strict';

  qunit.module('Unit | Helper | document stage');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = document_stage.documentStage(42);
    assert.ok(result);
  });

});