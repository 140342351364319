define('cat-frontend/components/vertical-slider', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    min: 0,
    _show: function _show() {
      if (this.get('scrollHeight') > 0) {
        this.$('input').show();
      } else {
        this.$('input').hide();
      }
    },
    observe: Ember['default'].observer('scrollHeight', function () {
      this._show();
    }),
    max: Ember['default'].computed('scrollHeight', function () {
      return this.get('scrollHeight');
    }),
    step: Ember['default'].computed('scrollHeight', function () {
      var height = this.get('scrollHeight');
      if (height < 10) {
        return 0.01;
      } else if (height < 100) {
        return 0.1;
      } else {
        return 1;
      }
    }),
    didInsertElement: function didInsertElement() {
      var height = Ember['default'].$('.js-center-space').height();
      var scrollBar = this.$('.js-scroll-bar');
      var halfScrollWidth = parseInt(scrollBar.outerHeight()) / 2;
      var thumbHalfWidth = 13;
      //css width of scroll slider thumb

      scrollBar.css({
        width: height,
        left: -(height / 2 - halfScrollWidth),
        top: height / 2 - (thumbHalfWidth + halfScrollWidth)
      });
      Ember['default'].run.next(this, function () {
        this._show();
      });

      var el = this.$()[0];
      var self = this;
      var $el = Ember['default'].$('.js-scroll-bar');

      if (el.addEventListener) {
        if ('onwheel' in document) {
          el.addEventListener("wheel", function (e) {
            e.preventDefault();

            var val = +Ember['default'].$('.js-scroll-bar').val();
            $el.val(val + e.deltaY);
            self.set('scrolled', val + e.deltaY);
          });
        } else {
          console.error('no onwheel property');
        }
      } else {
        console.error('no addEventListener');
      }
    }
  });

});