define('cat-frontend/controllers/tms/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    tmsRecordArray: Ember['default'].computed(function () {
      return this.store.peekAll('tm');
    }),

    filteredRecordArray: Ember['default'].computed('tmsRecordArray.[]', function () {
      return this.get('tmsRecordArray'); //.rejectBy('name', 'Default TM.tmx');
    }),

    itemsPerPage: 1,
    ready: false,
    queryParams: ['sort', 'page'],
    sort: 'createdAt:desc',
    page: 1,
    maxPage: Ember['default'].computed('tms.[]', 'itemsPerPage', function () {
      return Math.ceil(this.get('tms.length') / this.get('itemsPerPage'));
    }),

    sorting: Ember['default'].computed('sort', function () {
      return [this.get('sort')];
    }),
    tms: Ember['default'].computed.sort('filteredRecordArray', 'sorting'),

    filteredTms: Ember['default'].computed('tms.[]', 'page', 'maxPage', 'itemsPerPage', function () {
      var page = this.get('page');
      var maxPage = this.get('maxPage');
      var itemsPerPage = this.get('itemsPerPage');
      var leftLimit;
      var rightLimit;

      if (page > maxPage) {
        page = maxPage;
      }
      leftLimit = (page - 1) * itemsPerPage;
      rightLimit = leftLimit + itemsPerPage;

      return this.get('tms').slice(leftLimit, rightLimit);
    }),

    any: Ember['default'].computed('model.length', function () {
      return this.get('model.length') > 0;
    }),

    actions: {
      setItemsPerPage: function setItemsPerPage(data) {
        this.set('itemsPerPage', data);
        this.set('ready', true);
        return false;
      }
    }
  });

});