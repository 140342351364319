define('cat-frontend/transforms/raw', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return Ember['default'].isNone(serialized) ? {} : serialized;
    },

    serialize: function serialize(deserialized) {
      return Ember['default'].isNone(deserialized) ? {} : deserialized;
    }
  });

});