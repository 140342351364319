define('cat-frontend/tests/unit/mixins/click-outside-test.jshint', function () {

  'use strict';

  QUnit.module('JSHint - unit/mixins');
  QUnit.test('unit/mixins/click-outside-test.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'unit/mixins/click-outside-test.js should pass jshint.'); 
  });

});