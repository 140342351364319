define('cat-frontend/components/multi-select', ['exports', 'ember', 'cat-frontend/mixins/click-outside'], function (exports, Ember, ClickOutside) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(ClickOutside['default'], {
    tagName: 'span',
    showModal: false,
    selectOpened: false,
    classNameBindings: ['isOpened:form-select-opened'],

    isOpened: (function () {
      return this.get('selectOpened');
    }).property('selectOpened'),

    selectedValues: Ember['default'].computed('arrangedOptions.@each.selected', function () {
      var selectedField = this.get('isSelectedField');

      return this.get('options').filter(function (item) {
        return selectedField ? item.get(selectedField) : false;
      });
    }),

    title: Ember['default'].computed('selectedValues', 'selectOpened', function () {
      var field = this.get('field');
      var defaultTitle = this.get('defaultTitle');
      var selectOpened = this.get('selectOpened');
      var textLen = 23;

      if (selectOpened) {
        return defaultTitle;
      }

      var selectedValues = this.get('selectedValues').map(function (item) {
        return field ? item.get(field) : item;
      });

      if (selectedValues.length > 0) {
        var selectedValuesText = selectedValues.join(',');

        if (selectedValuesText.length > textLen) {
          selectedValuesText = "%@1...".fmt(selectedValuesText.slice(0, textLen - 3));
        } else {
          selectedValuesText = selectedValuesText.slice(0, textLen);
        }

        return selectedValuesText;
      }

      return defaultTitle;
    }),

    arrangedOptions: Ember['default'].computed('options.[]', function () {
      var field = this.get('field');
      var selectedField = this.get('isSelectedField');

      return this.get('options').map(function (item) {
        var value = field ? item.get(field) : item;
        var selected = selectedField ? item.get(selectedField) : false;

        return {
          object: item,
          value: value,
          selected: selected
        };
      });
    }),

    selectedArrangedOptions: Ember['default'].computed('arrangedOptions', function () {
      return this.get('arrangedOptions').slice(0, 4);
    }),

    splitArrangedOptions: Ember['default'].computed('arrangedOptions', function () {
      var options = this.get('arrangedOptions');
      var arrays = [];
      var size = options.length / 4;

      while (options.length > 0) {
        arrays.push(options.splice(0, size));
      }

      return arrays;
    }),

    changeOption: function changeOption(option) {
      var selectedField = this.get('isSelectedField');

      Ember['default'].set(option, 'selected', !option.selected);
      option.object.toggleProperty(selectedField);
    },

    onOutsideClick: function onOutsideClick() {
      if (this.get('selectOpened')) {
        this.toggleProperty('selectOpened');
      }
    },

    actions: {
      toggleSelectOpened: function toggleSelectOpened() {
        this.toggleProperty('selectOpened');
      },

      changeOption: function changeOption(option) {
        this.changeOption(option);
      },

      toggleAndChange: function toggleAndChange(option) {
        this.changeOption(option);
        this.toggleProperty('selectOpened');
      },

      moreOptions: function moreOptions() {
        this.set('showModal', true);
      },

      closeModal: function closeModal() {
        this.set('showModal', false);
      }

    }

  });

});