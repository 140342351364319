define('cat-frontend/routes/profile', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'cat-frontend/config/environment'], function (exports, Ember, AuthenticatedRouteMixin, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      var store = this.store;
      var adapter = store.adapterFor('user');

      return adapter.ajax(config['default'].APP.apiURL + '/users/me.json', 'GET').then(function (data) {
        return store.push(store.normalize('user', data.user));
      });
    }
  });

});