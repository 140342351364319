define('cat-frontend/components/login-modal', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    scrolled: 0,

    _observeScrolled: Ember['default'].observer('scrolled', function () {
      Ember['default'].$('.js-content').scrollTop(this.get('scrolled'));
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember['default'].run.schedule('afterRender', this, function () {
        var scroll = this.$('.js-content')[0].scrollHeight;
        var height = this.$('.js-content').height();

        this.set('scrollHeight', scroll - height);
      });

      var el = this.$('.js-content')[0];
      var scrollBar = this.$('.js-scroll-bar');
      var delta;

      if (el.addEventListener) {
        if ('onwheel' in document) {
          el.addEventListener("wheel", function (e) {
            delta = +_this.$('.js-content').scrollTop() + e.deltaY;
            scrollBar.val(delta);
          });
        } else {
          console.error('no onwheel property');
        }
      } else {
        console.error('no addEventListener');
      }
    },

    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      }
    }
  });

});