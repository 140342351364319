define('cat-frontend/torii-providers/facebook', ['exports', 'torii/providers/facebook-oauth2'], function (exports, FacebookOauth2Provider) {

  'use strict';

  exports['default'] = FacebookOauth2Provider['default'].extend({
    fetch: function fetch(data) {
      return data;
    }
  });

});