define('cat-frontend/router', ['exports', 'ember', 'cat-frontend/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('documents', {
      resetNamespace: true
    }, function () {
      this.route('new');
      this.route('document', {
        path: '/:document_id',
        resetNamespace: true
      });
    });
    this.route('tms', {
      path: '/tms',
      resetNamespace: true
    }, function () {
      this.route('new');
    });
    this.route('login');
    this.route('profile');
  });

  exports['default'] = Router;

});