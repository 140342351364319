define('cat-frontend/tests/unit/models/document-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel('document', 'Unit | Model | document', {
    needs: ['model:language', 'model:user', 'model:chunk']
  });

  ember_qunit.test('it exists', function (assert) {
    var model = this.subject();
    // var store = this.store();
    assert.ok(!!model);
  });

});