define('cat-frontend/components/document-properties', ['exports', 'ember', 'cat-frontend/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    store: Ember['default'].inject.service('store'),

    useMachineTranslation: Ember['default'].computed.alias('model.useMt'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember['default'].run.schedule('afterRender', this, function () {
        if (this.get('model.isMachineTranslation')) {
          this.send('startTranslation');
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.set('stopPoll', true);
    },

    translationRequested: false,
    stopPolling: false,

    isValid: Ember['default'].computed('model.{state,title,language.id,translateTo.id}', function () {
      return Ember['default'].isPresent(this.get('model.title')) && Ember['default'].isPresent(this.get('model.language.id')) && Ember['default'].isPresent(this.get('model.translateTo.id'));
    }),
    nextDisabled: Ember['default'].computed('model.language', 'model.translateTo', function () {
      return this.get('model.language.name') == undefined || this.get('model.translateTo.name') == undefined;
    }),
    startTranslationDisabled: Ember['default'].computed('isValid', 'translationRequested', 'model.isPolling', function () {
      return !this.get('translationRequested') && !this.get('isValid') || this.get('model.isPolling');
    }),

    timestamp: Ember['default'].computed('model.updatedAt', function () {
      if (moment) {
        return moment(this.get('model.upadedAt')).format("DD/MM/YYYY HH:mm");
      } else {
        return this.get('model.updatedAt');
      }
    }),

    directories: ['DIR1', 'DIR2', 'DIR3'],
    selectedDir: undefined,
    step: 1,

    firstStep: Ember['default'].computed('step', function () {
      return this.get('step') == 1;
    }),

    showRadial: Ember['default'].computed('translationRequested', 'model.isPolling', 'model.isMachineTranslation', function () {
      return this.get('translationRequested') || this.get('model.isPolling') && this.get('model.isMachineTranslation') || this.get('model.isMachineTranslation');
    }),

    documentStateChanged: Ember['default'].observer('model.state', function () {
      var state = this.get('model.state');
      console.info('document-properties: state changed: \'' + state + '\'');

      switch (state) {
        case 'translating':
          window.location.reload();
          this.set('translationRequested', false);
          break;

        case 'failed':
          this.set('translationRequested', false);
          console.error('document-properties: Document failed to prepare translation');
          swal('Error', 'an error #2 occurred, please try again later', 'error');
          this.set('model', this.store.createRecord('document', {}));
          break;
      }
    }),

    actions: {
      setTopic: function setTopic(topic) {
        var doc = this.get('model');
        if (!topic.isSelected) {
          doc.get('topics').then(function (topics) {
            topics.pushObject(topic);
          });
        } else {
          doc.get('topics').then(function (topics) {
            topics.removeObject(topic);
          });
        }
        topic.toggleSelected(doc);
      },
      setSelectState: function setSelectState(label, state) {
        if (!state) {
          Ember['default'].run.later(this, function () {
            this.set('opened' + label, state);
          }, 500);
        } else {
          this.set('opened' + label, state);
        }
      },
      toggleMachineTranslation: function toggleMachineTranslation() {
        this.toggleProperty('useMachineTranslation');
      },
      nextStep: function nextStep() {
        this.incrementProperty('step');
      },
      previousStep: function previousStep() {
        this.decrementProperty('step');
      },
      startTranslation: function startTranslation() {
        var _this = this;

        var model = this.get('model');
        model.save();
        this.set('translationRequested', true);

        var onSuccess = function onSuccess(document) {
          // Set timer and on finish check if state is not 'converted' yet, then
          var timeout = ENV['default'].constants.pollCycles * ENV['default'].constants.pollTimeOut;
          Ember['default'].run.later(function () {
            if (_this.get('translationRequested')) {
              if (document.get('model.state') !== 'translating') {
                console.error('document-properties: is still polling after ' + timeout + ', it’s state is \'' + document.get('state') + '\'');
                swal('Error', 'an error #3 occurred, please try again later', 'error');
              }
              _this.set('translationRequested', false);
              //add transitionToRoute
            }
          }, timeout);
        };

        var onFail = function onFail() {
          _this.set('translationRequested', false);
          swal('Error', 'an error #4 occurred, please try again later', 'error');
        };

        if (this.get('model.isMachineTranslation')) {
          return model.reload().then(onSuccess, onFail);
        } else {
          return model.save().then(function () {
            return model.startTranslation().then(function () {
              model.reload().then(onSuccess, onFail);
            }, onFail);
          }, onFail);
        }
      }
    }
  });

});