define('cat-frontend/templates/components/vertical-slider', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 119
          }
        },
        "moduleName": "cat-frontend/templates/components/vertical-slider.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["inline","input",[],["type","range","min",["subexpr","@mut",[["get","min",["loc",[null,[1,25],[1,28]]]]],[],[]],"max",["subexpr","@mut",[["get","scrollHeight",["loc",[null,[1,33],[1,45]]]]],[],[]],"value",["subexpr","@mut",[["get","scrolled",["loc",[null,[1,52],[1,60]]]]],[],[]],"step",["subexpr","@mut",[["get","step",["loc",[null,[1,66],[1,70]]]]],[],[]],"class","center-space__scroll-bar js-scroll-bar"],["loc",[null,[1,0],[1,119]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});