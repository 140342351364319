define('cat-frontend/tests/components/chunks-editor.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/chunks-editor.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'components/chunks-editor.js should pass jshint.\ncomponents/chunks-editor.js: line 94, col 29, Missing semicolon.\ncomponents/chunks-editor.js: line 97, col 29, Missing semicolon.\n\n2 errors'); 
  });

});