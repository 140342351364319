define('cat-frontend/templates/documents/new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 123
            }
          },
          "moduleName": "cat-frontend/templates/documents/new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","new-document__progress");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","radial-progress",[],["number",["subexpr","@mut",[["get","model.stageProgress",["loc",[null,[1,78],[1,97]]]]],[],[]],"stage",["subexpr","@mut",[["get","model.stage",["loc",[null,[1,104],[1,115]]]]],[],[]]],["loc",[null,[1,53],[1,117]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 293
                },
                "end": {
                  "line": 1,
                  "column": 585
                }
              },
              "moduleName": "cat-frontend/templates/documents/new.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","file-picker__text_top");
              var el2 = dom.createTextNode("Drag & Drop to upload");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","file-picker__text_middle");
              var el2 = dom.createTextNode("or");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","file-picker__text_bottom");
              var el2 = dom.createTextNode("Select files");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 169
              },
              "end": {
                "line": 1,
                "column": 893
              }
            },
            "moduleName": "cat-frontend/templates/documents/new.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","file-picker__container");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","new-document__button");
            var el3 = dom.createElement("input");
            dom.setAttribute(el3,"type","submit");
            dom.setAttribute(el3,"value","Upload");
            dom.setAttribute(el3,"class","btn btn-success new-document__button_upload");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var element1 = dom.childAt(element0, [2, 0]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element0,0,0);
            morphs[1] = dom.createMorphAt(element0,1,1);
            morphs[2] = dom.createAttrMorph(element1, 'disabled');
            morphs[3] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["block","file-picker",[],["fileLoaded","fileLoaded","readAs","readAsDataURL","preview",false,"progress",false,"accept",".txt,.pdf,.docx,.doc,.html"],0,null,["loc",[null,[1,293],[1,601]]]],
            ["inline","em-text",[],["class","new-document__textarea","property","text","placeholder","or Start typing here","rows",10],["loc",[null,[1,601],[1,702]]]],
            ["attribute","disabled",["get","NoInput",["loc",[null,[1,754],[1,761]]]]],
            ["element","action",["prepareText"],["on","click"],["loc",[null,[1,793],[1,828]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 123
            },
            "end": {
              "line": 1,
              "column": 905
            }
          },
          "moduleName": "cat-frontend/templates/documents/new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","new-document__icon");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","em-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[1,186],[1,191]]]]],[],[]],"submit_button",false,"enctype","multipart/form-data","v_icons",false],0,null,["loc",[null,[1,169],[1,905]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 912
          }
        },
        "moduleName": "cat-frontend/templates/documents/new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","isLoading",["loc",[null,[1,6],[1,15]]]]],[],0,1,["loc",[null,[1,0],[1,912]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});