define('cat-frontend/models/tm', ['exports', 'ember', 'ember-data', 'ember-validations'], function (exports, Ember, DS, EmberValidations) {

  'use strict';

  exports['default'] = (function () {
    var TM;

    TM = DS['default'].Model.extend(EmberValidations['default'], {
      name: DS['default'].attr('string'),
      file: DS['default'].attr('file'),
      state: DS['default'].attr('string'),
      createdAt: DS['default'].attr('date'),
      updatedAt: DS['default'].attr('date'),
      'public': DS['default'].attr('boolean', { defaultValue: false }),
      user: DS['default'].belongsTo('user', { async: true }),

      isntValid: Ember['default'].computed.not('isValid')
    });

    TM.reopen({
      validations: {
        name: {
          presence: true
        },
        file: {
          presence: true
        }
      }
    });

    return TM;
  })();

});