define('cat-frontend/components/x-progressbar', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['progress'],

    percent: Ember['default'].computed('value', function () {
      return (this.get('value') * 100).toFixed();
    }),

    percentText: Ember['default'].computed('percent', function () {
      return this.get('percent') + '%';
    }),

    progressStyle: Ember['default'].computed('percent', function () {
      return new Ember['default'].Handlebars.SafeString('min-width: 2em; width: ' + this.get('percentText'));
    }),

    status: Ember['default'].computed('percent', function () {
      var percent = this.get('percent');
      if (percent < 40) {
        return 'progress-bar-danger';
      } else if (percent < 60) {
        return 'progress-bar-warning';
      } else if (percent < 100) {
        return 'progress-bar-info';
      } else {
        return 'progress-bar-success';
      }
    })
  });

});