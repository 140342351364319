define('cat-frontend/tests/components/language-selector.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/language-selector.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'components/language-selector.js should pass jshint.'); 
  });

});