define('cat-frontend/serializers/document', ['exports', 'ember-data', 'active-model-adapter'], function (exports, DS, active_model_adapter) {

  'use strict';

  exports['default'] = active_model_adapter.ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      topics: { serialize: 'ids', deserialize: 'ids' }
    }
  });

});