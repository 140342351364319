define('cat-frontend/mixins/click-outside', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    onOutsideClick: Ember['default'].K,

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = $(event.target);

      if (!$target.closest($element).length) {
        this.onOutsideClick();
      }
    },

    setupOutsideClickListener: Ember['default'].on('didInsertElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return Ember['default'].$(document).on('click', clickHandler);
    }),

    removeOutsideClickListener: Ember['default'].on('willDestroyElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return Ember['default'].$(document).off('click', clickHandler);
    })
  });

});