define('cat-frontend/controllers/documents/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    itemsPerPage: 1,
    ready: false,
    queryParams: ['sort', 'page'],
    sort: 'createdAt:desc',
    page: 1,
    maxPage: Ember['default'].computed('documents.[]', 'itemsPerPage', function () {
      return Math.ceil(this.get('documents.length') / this.get('itemsPerPage'));
    }),
    sorting: Ember['default'].computed('sort', function () {
      return [this.get('sort')];
    }),
    documents: Ember['default'].computed.sort('model', 'sorting'),

    filteredDocuments: Ember['default'].computed('documents.[]', 'page', 'maxPage', 'itemsPerPage', function () {
      var page = this.get('page');
      var maxPage = this.get('maxPage');
      var itemsPerPage = this.get('itemsPerPage');
      var leftLimit;
      var rightLimit;

      if (page > maxPage) {
        page = maxPage;
      }
      leftLimit = (page - 1) * itemsPerPage;
      rightLimit = leftLimit + itemsPerPage;

      return this.get('documents').slice(leftLimit, rightLimit);
    }),

    any: Ember['default'].computed('model.length', function () {
      return this.get('model.length') > 0;
    }),

    isOne: Ember['default'].computed('model', function () {
      return this.get('model.length') === 1;
    }),

    actions: {
      setItemsPerPage: function setItemsPerPage(data) {
        this.set('itemsPerPage', data);
        this.set('ready', true);
        return false;
      },
      transitionToDocument: function transitionToDocument(document) {
        this.transitionToRoute('document', document);
      }
    }
  });

});