define('cat-frontend/initializers/csrf-service', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    name: 'rails-csrf',
    initialize: function initialize(container, app) {
      app.inject('route', 'csrf', 'service:csrf');
      app.inject('controller', 'csrf', 'service:csrf');
    }
  };

});