define('cat-frontend/components/tm-table', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('itemsPerPage') === 1) {
        this._calculate();
      }
    },

    _calculate: function _calculate() {
      var _this = this;

      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        var table = Ember['default'].$('.js-table-tms');
        var rowHeight = table.find('tbody').height();
        var availableSpace = table.height() - (table.find('table').height() - rowHeight);
        _this.sendAction('setItemsPerPage', Math.floor(availableSpace / rowHeight));
      });
    },

    tagName: ''
  });

});