define('cat-frontend/components/user-profile-drop', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    session: Ember['default'].inject.service('session'),

    actions: {
      togglePopup: function togglePopup() {
        this.set('attachElement', this.$().find('.js-popup-target'));
        this.set('showPopup', true);
      },
      closePopup: function closePopup() {
        this.set('showPopup', false);
      },
      exit: function exit() {
        this.get('session').invalidate();
      }
    }

  });

});