define('cat-frontend/controllers/document', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    queryParams: ['page'],
    page: 1,

    eventsService: Ember['default'].inject.service('events'),
    init: function init() {
      this._super();
      this.get('eventsService').on('focus', this, function (data) {
        Ember['default'].run.cancel(this.get('runId'));

        var runId = Ember['default'].run.later(this, function () {
          this.set('focus', data);
        }, 100);
        this.set('runId', runId);
      });

      // Remove concordance if any chunk was focused in.
      // Needed, since concordance results stay displayed even when new chunk was selected.
      this.get('eventsService').on('chunkFocusedIn', (function () {
        this.set('wordForConcordance', null);
      }).bind(this));
    },
    destroy: function destroy() {
      this.get('eventsService').off('focus');
    },
    focus: false,
    concordanceSuggestions: null,
    maxSuggestionsLength: 15,
    showBottomBox: Ember['default'].computed('focus', 'originalChunk', 'wordForConcordance', 'translatedChunk.@each.suggestions', function () {
      return this.get('focus') && !!this.get('originalChunk') && this.get('translatedChunk.suggestions.length') || this.get('wordForConcordance');
    }),
    translateFrom: Ember['default'].computed.alias('model.language.code'),
    translateTo: Ember['default'].computed.alias('model.translateTo.code'),
    translateToLanguages: Ember['default'].computed('languages.@each.id', 'languageId', function () {
      var languages = this.get('languages');
      var languageId = this.get('languageId');
      if (languageId) {
        languages = languages.rejectBy('id', languageId);
      }
      return languages;
    }),
    languageId: Ember['default'].computed.alias('model.language.id'),
    suggestions: Ember['default'].computed('originalChunk.translation.suggestions', function () {
      var self = this;
      if (this.get('originalChunk.translation.suggestions')) {
        return this.get('originalChunk.translation.suggestions').filter(function (item, index) {
          return index < self.get('maxSuggestionsLength');
        });
      }
      return [];
    }),
    originalChunk: Ember['default'].computed.alias('model.translatedChunk'),
    translatedChunk: Ember['default'].computed.alias('originalChunk.translation'),
    scrollHeightValue: Ember['default'].computed('originalScrollHeight', 'translatedScrollHeight', function () {
      var original = this.get('originalScrollHeight');
      var translated = this.get('translatedScrollHeight');
      var editorHeight = Ember['default'].$('.js-text-scroll').height();

      return original < translated ? translated - editorHeight : original - editorHeight;
    }),
    scrolled: 0,
    editorScrolled: 0,
    _observeScrolled: Ember['default'].observer('scrolled', function () {
      Ember['default'].$('.js-text-scroll').scrollTop(this.get('scrolled'));
      this.set('editorScrolled', this.get('scrolled'));
    }),
    actions: {
      concordance: function concordance(word) {
        var self = this;
        this.set('wordForConcordance', word);
        this.set('concordanceSuggestions', []);
        this.set('noConcordanceData', false);
        if (word) {
          var onSuccess = function onSuccess(data) {
            var suggestions = [];
            var openingTag = '<span class="concordance__text_word">';
            var closingTag = '</span>';

            if (data && data.suggestions && data.suggestions.length) {
              suggestions = data.suggestions.map(function (item) {
                item['text1'] = item['text1'].replace(new RegExp('<\\*\\*\\*', 'g'), openingTag).replace(new RegExp('\\*\\*\\*>', 'g'), closingTag);
                item['text2'] = item['text2'].replace(new RegExp('<\\*\\*\\*', 'g'), openingTag).replace(new RegExp('\\*\\*\\*>', 'g'), closingTag);

                return item;
              });
              self.set('concordanceSuggestions', suggestions);
            } else {
              self.set('noConcordanceData', true);
            }
          };
          var onFail = function onFail() {
            swal('Error', 'Error using concordance search', 'error');
          };

          this.get('model').concordance(word).then(onSuccess, onFail);
        }
      }
    }
  });

});