define('cat-frontend/initializers/faye', ['exports'], function (exports) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    application.inject('controller', 'faye', 'service:faye');
  }

  exports['default'] = {
    name: 'faye',
    initialize: initialize
  };

});