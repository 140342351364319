define('cat-frontend/helpers/document-stage', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.documentStage = documentStage;

  function documentStage(params /*, hash*/) {
    var doc = params[0].getProperties('state', 'stage', 'stageProgress');
    if (doc.state === 'machine_translation') {
      doc.state = null;
    }
    return (doc.state ? doc.state : '') + ' ' + (doc.stage ? doc.stage : '') + ' ' + (doc.stageProgress ? '(' + doc.stageProgress + ')' : '');
  }

  exports['default'] = Ember['default'].Helper.helper(documentStage);

});