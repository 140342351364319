define('cat-frontend/routes/tms/new', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      return this.store.createRecord('tm', {});
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('notUploaded', true);
      controller.set('isLoading', false);
    }
  });

});