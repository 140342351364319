define('cat-frontend/instance-initializers/walk-providers', ['exports', 'torii/lib/container-utils', 'torii/configuration'], function (exports, container_utils, torii__configuration) {

  'use strict';

  exports['default'] = {
    name: 'torii-walk-providers',
    initialize: function initialize(applicationInstance) {
      var configuration = torii__configuration.getConfiguration();
      // Walk all configured providers and eagerly instantiate
      // them. This gives providers with initialization side effects
      // like facebook-connect a chance to load up assets.
      for (var key in configuration.providers) {
        if (configuration.providers.hasOwnProperty(key)) {
          container_utils.lookup(applicationInstance, 'torii-provider:' + key);
        }
      }
    }
  };

});