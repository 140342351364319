define('cat-frontend/tests/unit/components/x-chunk-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

  'use strict';

  ember_qunit.moduleForComponent('x-chunk', 'Unit | Component | x chunk', {
    // Specify the other units that are required for this test
    needs: ['component:x-editable'],
    unit: true
  });

  ember_qunit.test('it renders', function (assert) {
    assert.expect(2);

    // Creates the component instance
    var component = this.subject();
    assert.equal(component._state, 'preRender');

    Ember['default'].run(function () {
      var chunk = Ember['default'].Object.create({ isLoaded: true });
      component.set('chunk', chunk);
    });

    // Renders the component to the page
    this.render();
    assert.equal(component._state, 'inDOM');
  });

});