define('cat-frontend/components/x-editable', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    eventsService: Ember['default'].inject.service('events'),

    tagName: 'span',
    attributeBindings: ['contentEditable'],
    classNames: ['editable-chunk'],
    classNameBindings: ['hasText', 'focusedInChunk', 'translationType'],

    // Variables:
    editable: false,
    isUserTyping: false,
    plaintext: true,
    translating: Ember['default'].computed.alias('chunk.original.translating'),
    translated: Ember['default'].computed.alias('chunk.translated'),
    $el: null,
    trimmed: false,
    wasFocused: false,
    focusedOutRecently: true,
    // Properties:
    contentEditable: Ember['default'].computed('editable', function () {
      var editable = this.get('editable');

      return editable ? 'true' : undefined;
    }),

    hasText: Ember['default'].computed('value', 'isUserTyping', 'translated', function () {
      return this.get('chunk.original.text') !== this.get('value') || this.get('translated');
    }),

    // Processors:
    processValue: function processValue() {
      if (!this.get('isUserTyping') && this.get('value')) {
        return this.setContent();
      }
    },

    // Observers:
    valueObserver: Ember['default'].observer('value', 'isUserTyping', function () {
      Ember['default'].run.once(this, 'processValue');
    }),

    focusObserver: Ember['default'].observer('chunk.focused', function () {
      if (this.get('chunk.focused')) {
        Ember['default'].run.next(this, function () {
          var doc = document.getElementById(this.get('elementId'));
          doc.focus();
        });
      }
    }),

    _getCaretPosition: function _getCaretPosition(el) {
      var caretPos = 0;
      var sel;
      var range;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
          range = sel.getRangeAt(0);
          if (range.commonAncestorContainer.parentNode === el) {
            caretPos = range.endOffset;
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() === el) {
          var tempEl = document.createElement("span");
          el.insertBefore(tempEl, el.firstChild);
          var tempRange = range.duplicate();
          tempRange.moveToElementText(tempEl);
          tempRange.setEndPoint("EndToEnd", range);
          caretPos = tempRange.text.length;
        }
      }
      return caretPos;
    },

    _textSelect: function _textSelect(num) {
      var el = document.getElementById(this.get('elementId'));
      var range = document.createRange();
      var sel = window.getSelection();
      range.setStart(el.childNodes[0], num);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    },

    _findWidth: function _findWidth() {
      var f = this.get('$el').css('font');
      var string = this.get('$el').text();
      var o = Ember['default'].$('<div>' + string + '</div>').css({ 'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font': f }).appendTo(Ember['default'].$('body')),
          w = o.width();

      o.remove();

      return w;
    },

    // Events:
    didInsertElement: function didInsertElement() {
      this.set('$el', this.$());
      this.get('eventsService').on('erase', this, function () {
        if (this.get('wasFocused')) {
          this.set('value', '');
          this.set('translated', false);
        }
      });
      return this.setContent();
    },

    willDestroyElement: function willDestroyElement() {
      this.setProperties({
        translating: false,
        'chunk.focused': false
      });
    },

    focusIn: function focusIn() {
      var _this = this;

      this.get('eventsService').trigger('chunkFocusedIn'); // Emit global event, that chunk was focused in.

      if (this.get('focusedOutRecently')) {
        this.set('wasFocused', true);
        this.set('translating', true);
        this.set('focusedOutRecently', false);

        this.get('eventsService').trigger('focus', true);

        this.get('chunk.suggestions').then(function (suggestions) {
          var a = suggestions.findBy('text', _this.get('chunk.text'));

          if (a) {
            _this.set('translationType', a.get('suggestionType'));
          }
        });

        if (!this.get('hasText')) {
          var nbspWidth = 4;
          var count = Math.round(this._findWidth() / nbspWidth);
          var string = '&nbsp;<wbr>'.repeat(count);

          this.set('focusedInChunk', true);
          this.get('$el').html(string);
          this.set('trimmed', false);

          Ember['default'].run.next(this, function () {
            this._textSelect(0);
            this.get('$el').css({
              'min-width': this.get('$el').width(),
              'min-height': this.get('$el').height()
            });
          });
        } else {
          Ember['default'].run.next(this, function () {
            this._textSelect(this.get('$el').text().length);
          });
        }
      } else {
        this.get('$el').blur();
        this.set('focusedOutRecently', true);
      }
    },

    focusOut: function focusOut() {
      this.get('$el').css({
        'min-width': 0,
        'min-height': 0
      });
      this.set('focusedInChunk', false);
      if (this.get('$el').text().trim() === '') {
        this.setProperties({
          'value': this.get('chunk.original.text'),
          'translated': false
        });
        this.get('$el').html(Ember['default'].Handlebars.Utils.escapeExpression(this.get('chunk.original.text')));
      }

      this.get('eventsService').trigger('focus', false);

      Ember['default'].run.later(this, function () {
        this.set('wasFocused', false);
        this.set('focusedOutRecently', true);
      }, 100);

      // Force chunk save on focus out
      this.setValue();
      if (this.get('chunk.isTranslated')) {
        this.get('chunk').save();
      }

      return this.setProperties({ 'chunk.focused': false, isUserTyping: false, translating: false });
    },

    //fix problem in chrome
    click: function click() {
      if (!this.get('hasText')) {
        this._textSelect(0);
      }
    },

    keyDown: function keyDown(event) {
      if (!this.get('trimmed')) {
        var position = this._getCaretPosition(event.currentTarget);
        var doc = document.getElementById(this.get('elementId'));
        doc.textContent = doc.textContent.trim();

        // console.log(doc.textContent.trim());

        this.set('trimmed', true);
        if (position) {
          this._textSelect(position);
        }
      }
      return this.setProperties({
        'isUserTyping': true,
        'translated': true,
        'fromSuggest': false
      });
    },

    keyUp: function keyUp() {
      this.setValue();
    },

    setValue: function setValue() {
      if (this.get('plaintext')) {
        return this.set('value', this.get('$el').text());
      } else {
        return this.set('value', this.get('$el').html());
      }
    },

    //render our own html so there are no metamorphs to get screwed up when the user changes the html
    render: function render(buffer) {
      buffer.push(this.get('value'));
    },

    setContent: function setContent() {
      return this.get('$el').html(Ember['default'].Handlebars.Utils.escapeExpression(this.get('value')));
    }
  });

});