define('cat-frontend/templates/components/documents-table', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 183
            },
            "end": {
              "line": 1,
              "column": 215
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-table.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Language");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 236
            },
            "end": {
              "line": 1,
              "column": 266
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-table.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Status");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 352
            },
            "end": {
              "line": 1,
              "column": 382
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-table.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Action");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 498
              },
              "end": {
                "line": 1,
                "column": 561
              }
            },
            "moduleName": "cat-frontend/templates/components/documents-table.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","document.title",["loc",[null,[1,543],[1,561]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 419
            },
            "end": {
              "line": 1,
              "column": 815
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-table.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("tr");
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" → ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
          morphs[2] = dom.createMorphAt(element1,0,0);
          morphs[3] = dom.createMorphAt(element1,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [2]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [4]),0,0);
          return morphs;
        },
        statements: [
          ["element","action",["goTo",["get","document",["loc",[null,[1,472],[1,480]]]]],["on","click"],["loc",[null,[1,456],[1,493]]]],
          ["block","link-to",["document",["get","document",["loc",[null,[1,520],[1,528]]]]],["class","link"],0,null,["loc",[null,[1,498],[1,573]]]],
          ["content","document.language.code",["loc",[null,[1,582],[1,608]]]],
          ["content","document.translateTo.code",["loc",[null,[1,611],[1,640]]]],
          ["inline","document-stage",[["get","document",["loc",[null,[1,666],[1,674]]]]],[],["loc",[null,[1,649],[1,676]]]],
          ["inline","moment-format",[["get","document.createdAt",["loc",[null,[1,701],[1,719]]]],"D MMMM YYYY HH:mm "],[],["loc",[null,[1,685],[1,742]]]],
          ["inline","document-controls",[],["document",["subexpr","@mut",[["get","document",["loc",[null,[1,780],[1,788]]]]],[],[]],"translate",true],["loc",[null,[1,751],[1,805]]]]
        ],
        locals: ["document"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 846
          }
        },
        "moduleName": "cat-frontend/templates/components/documents-table.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","documents-table__container js-table-documents");
        var el2 = dom.createElement("table");
        dom.setAttribute(el2,"class","documents-table");
        var el3 = dom.createElement("thead");
        var el4 = dom.createElement("tr");
        var el5 = dom.createElement("th");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tbody");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 0]);
        var element3 = dom.childAt(element2, [0, 0]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [4]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        return morphs;
      },
      statements: [
        ["inline","document-table-header",[],["header","Name","sort","title","link","documents"],["loc",[null,[1,105],[1,174]]]],
        ["block","link-to",["documents"],[],0,null,["loc",[null,[1,183],[1,227]]]],
        ["block","link-to",["documents"],[],1,null,["loc",[null,[1,236],[1,278]]]],
        ["inline","document-table-header",[],["header","Date","sort","createdAt"],["loc",[null,[1,287],[1,343]]]],
        ["block","link-to",["documents"],[],2,null,["loc",[null,[1,352],[1,394]]]],
        ["block","each",[["get","documents",["loc",[null,[1,427],[1,436]]]]],[],3,null,["loc",[null,[1,419],[1,824]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});