define('cat-frontend/models/suggestion', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    text: DS['default'].attr('string'),
    chunk: DS['default'].belongsTo('chunk', { inverse: 'suggestions', async: true }),
    rank: DS['default'].attr('number'),
    extraLexemes: DS['default'].attr('number'),
    userSuggested: DS['default'].attr('boolean'),
    suggestionType: DS['default'].attr('string'),
    topicNames: DS['default'].attr(),
    tmName: DS['default'].attr('string'),

    isExactMatch: Ember['default'].computed.equal('suggestionType', 'exact'),
    isFuzzyMatch: Ember['default'].computed.equal('suggestionType', 'fuzzy'),
    isMT: Ember['default'].computed.equal('suggestionType', 'mt'),

    showAttribution: Ember['default'].computed('topicNames', 'tmName', function () {
      return this.get('topicNames') && this.get('topicNames').length > 0 || this.get('tmName');
    }),

    details: Ember['default'].computed('topicNames', 'tmName', function () {
      var tags = '';
      if (this.get('topicNames') && this.get('topicNames').length > 0) {
        tags = 'Tag: ' + this.get('topicNames').join(', ') + ';';
      }
      var source = '';
      if (this.get('tmName')) {
        source = 'Source: ' + this.get('tmName');
      }
      return '(' + tags + source + ')';
    }),

    suggestionItemClass: Ember['default'].computed('suggestionType', function () {
      var type = this.get('suggestionType');
      if (type) {
        return type + '-match';
      }
    })
  });

});