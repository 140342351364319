define('cat-frontend/tests/routes/document.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes');
  QUnit.test('routes/document.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'routes/document.js should pass jshint.\nroutes/document.js: line 8, col 11, \'store\' is defined but never used.\n\n1 error'); 
  });

});