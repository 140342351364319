define('cat-frontend/tests/components/document-properties.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/document-properties.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'components/document-properties.js should pass jshint.\ncomponents/document-properties.js: line 41, col 48, Expected \'===\' and instead saw \'==\'.\ncomponents/document-properties.js: line 42, col 51, Expected \'===\' and instead saw \'==\'.\ncomponents/document-properties.js: line 67, col 31, Expected \'===\' and instead saw \'==\'.\ncomponents/document-properties.js: line 55, col 9, \'moment\' is not defined.\ncomponents/document-properties.js: line 56, col 14, \'moment\' is not defined.\n\n5 errors'); 
  });

});