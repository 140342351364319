define('cat-frontend/components/chunks-editor', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    eventsService: Ember['default'].inject.service('events'),

    _calculateHeight: function _calculateHeight() {
      this.set('scrollHeight', this.$()[0].scrollHeight);
    },
    didInsertElement: function didInsertElement() {
      var self = this;

      this.get('eventsService').trigger('setInDocument', true);

      this.set('$scroll', Ember['default'].$('.js-text-scroll'));

      var target = document.querySelector('#' + this.get('elementId'));
      var observer = new MutationObserver(function () {
        self._calculateHeight();
      });
      var config = { attributes: false, childList: true, characterData: true, subtree: true };
      observer.observe(target, config);
      this.set('_observer', observer);

      Ember['default'].run.schedule('afterRender', this, function () {
        this._calculateHeight();
      });

      var el = this.$()[0];
      var scrollBar = Ember['default'].$('.js-scroll-bar');

      if (el.addEventListener) {
        if ('onwheel' in document) {
          el.addEventListener("wheel", function (e) {
            e.preventDefault();

            var delta = +self.get('editorScrolled') + e.deltaY;

            self.get('$scroll').scrollTop(delta);
            scrollBar.val(delta);

            if (self.get('max') >= delta && delta >= 0) {
              self.set('editorScrolled', delta);
            }
          });
        } else {
          console.error('no onwheel property');
        }
      } else {
        console.error('no addEventListener');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('_observer').disconnect();
      this.get('eventsService').trigger('setInDocument', false);
    },
    classNameBindings: ['cssClass', 'selector'],
    classNames: ['js-text-scroll'],
    selector: Ember['default'].computed('label', function () {
      return this.get('label') === 'From' ? 'js-text-original' : 'js-text-translation';
    }),
    cssClass: Ember['default'].computed('label', function () {
      return this.get('label') === 'From' ? 'b-document__original_chunks' : 'b-document__translation_chunks';
    }),
    currentPageChunks: Ember['default'].computed('page', 'model.rootChunks.[]', function () {
      return this.get('model.rootChunks').filterBy('pageNumber', this.get('page')).sortBy('position');
    }),

    _getSelectedText: function getSelectedText() {
      if (window.getSelection) {
        return window.getSelection().toString();
      } else if (document.selection) {
        return document.selection.createRange().text;
      }
      return '';
    },

    concordanceSearch: function concordanceSearch() {
      if (this.get('label') === 'From') {
        var text = this._getSelectedText();
        if (text) {
          window.selected = true;
          if (text.split(' ').length <= 5) {
            this.sendAction('concordance', text);
          }
        }
      }
    },

    mouseUp: function mouseUp() {
      this.concordanceSearch();
    },
    mouseLeave: function mouseLeave() {
      this.concordanceSearch();
    },
    mouseDown: function mouseDown() {
      this.sendAction('concordance', false);
      window.selected = false;
    }
  });

});