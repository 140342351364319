define('cat-frontend/tests/controllers/tms/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/tms');
  QUnit.test('controllers/tms/index.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'controllers/tms/index.js should pass jshint.'); 
  });

});