define('cat-frontend/tests/controllers/application.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers');
  QUnit.test('controllers/application.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'controllers/application.js should pass jshint.'); 
  });

});