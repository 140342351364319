define('cat-frontend/templates/components/documents-pagination', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 35
            },
            "end": {
              "line": 1,
              "column": 142
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-pagination.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination__list");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link-to",[1,["subexpr","query-params",[],["page",1],["loc",[null,[1,94],[1,115]]]]],["class","list__page"],["loc",[null,[1,82],[1,136]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 149
            },
            "end": {
              "line": 1,
              "column": 208
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-pagination.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination__list");
          var el2 = dom.createTextNode("...");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 215
            },
            "end": {
              "line": 1,
              "column": 336
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-pagination.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination__list");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link-to",[["get","page",["loc",[null,[1,280],[1,284]]]],["subexpr","query-params",[],["page",["get","page",["loc",[null,[1,304],[1,308]]]]],["loc",[null,[1,285],[1,309]]]]],["class","list__page"],["loc",[null,[1,270],[1,330]]]]
        ],
        locals: ["page"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 345
            },
            "end": {
              "line": 1,
              "column": 405
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-pagination.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination__list");
          var el2 = dom.createTextNode("...");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 412
            },
            "end": {
              "line": 1,
              "column": 532
            }
          },
          "moduleName": "cat-frontend/templates/components/documents-pagination.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination__list");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link-to",[["get","lastPage",["loc",[null,[1,468],[1,476]]]],["subexpr","query-params",[],["page",["get","lastPage",["loc",[null,[1,496],[1,504]]]]],["loc",[null,[1,477],[1,505]]]]],["class","list__page"],["loc",[null,[1,458],[1,526]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 545
          }
        },
        "moduleName": "cat-frontend/templates/components/documents-pagination.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","documents__pagination");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0,0,0);
        morphs[1] = dom.createMorphAt(element0,1,1);
        morphs[2] = dom.createMorphAt(element0,2,2);
        morphs[3] = dom.createMorphAt(element0,3,3);
        morphs[4] = dom.createMorphAt(element0,4,4);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showFirst",["loc",[null,[1,41],[1,50]]]]],[],0,null,["loc",[null,[1,35],[1,149]]]],
        ["block","if",[["get","showLeftDots",["loc",[null,[1,155],[1,167]]]]],[],1,null,["loc",[null,[1,149],[1,215]]]],
        ["block","each",[["get","pages",["loc",[null,[1,223],[1,228]]]]],[],2,null,["loc",[null,[1,215],[1,345]]]],
        ["block","if",[["get","showRightDots",["loc",[null,[1,351],[1,364]]]]],[],3,null,["loc",[null,[1,345],[1,412]]]],
        ["block","if",[["get","showLast",["loc",[null,[1,418],[1,426]]]]],[],4,null,["loc",[null,[1,412],[1,539]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});