define('cat-frontend/tests/components/l-select.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/l-select.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'components/l-select.js should pass jshint.\ncomponents/l-select.js: line 44, col 19, \'e\' is defined but never used.\n\n1 error'); 
  });

});