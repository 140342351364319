define('cat-frontend/templates/components/x-progressbar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 174
          }
        },
        "moduleName": "cat-frontend/templates/components/x-progressbar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"role","progressbar");
        dom.setAttribute(el1,"aria-valuemin","0");
        dom.setAttribute(el1,"aria-valuemax","100");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        morphs[1] = dom.createAttrMorph(element0, 'aria-valuenow');
        morphs[2] = dom.createAttrMorph(element0, 'class');
        morphs[3] = dom.createMorphAt(element0,0,0);
        morphs[4] = dom.createMorphAt(element0,1,1);
        return morphs;
      },
      statements: [
        ["attribute","style",["get","progressStyle",["loc",[null,[1,32],[1,45]]]]],
        ["attribute","aria-valuenow",["get","percent",["loc",[null,[1,64],[1,71]]]]],
        ["attribute","class",["concat",["progress-bar ",["get","status",["loc",[null,[1,134],[1,140]]]]]]],
        ["content","percentText",["loc",[null,[1,144],[1,159]]]],
        ["content","yield",["loc",[null,[1,159],[1,168]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});