define('cat-frontend/components/documents-pagination', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    pages: Ember['default'].computed('length', 'currentPage', function () {
      var pages = this.get('pagesNumber');
      if (!pages) {
        pages = Math.ceil(this.get('length') / this.get('itemsPerPage'));
      }

      this.set('lastPage', pages);
      var array = [];
      var showLast = true;
      var showFirst = true;
      var page = this.get('currentPage');

      if (page > pages && !pages) {
        page = 1;
      } else if (page > pages) {
        page = 1;
      }

      if (!pages || pages === 1) {
        showFirst = false;
        showLast = false;
      }

      if (page < 1) {
        page = 1;
      }

      var first = 1;
      var last = pages;
      var showLeftDots = false;
      var showRightDots = false;
      var i = 0;

      if (page - 1 > first + 1) {
        showLeftDots = true;
        array[i++] = page - 1;
      } else if (page - 1 > first) {
        array[i++] = page - 1;
      }

      if (page !== first && page !== last) {
        array[i++] = page;
      }

      if (page + 1 < last - 1) {
        showRightDots = true;
        array[i++] = page + 1;
      } else if (page + 1 < last) {
        array[i++] = page + 1;
      }

      Ember['default'].run.scheduleOnce('sync', this, function () {
        if (showLast) {
          this.set('showLast', true);
        } else {
          this.set('showLast', false);
        }

        if (showFirst) {
          this.set('showFirst', true);
        } else {
          this.set('showFirst', false);
        }

        if (showLeftDots) {
          this.set('showLeftDots', true);
        } else {
          this.set('showLeftDots', false);
        }

        if (showRightDots) {
          this.set('showRightDots', true);
        } else {
          this.set('showRightDots', false);
        }
      });

      return array;
    })
  });

});