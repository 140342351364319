define('cat-frontend/components/translation-suggestion', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: '',
    _process: function _process() {
      if (this.get('translatedChunk.text') !== this.get('suggestion.text') || !this.get('translatedChunk.translated')) {
        this.get('translatedChunk').setProperties({
          'text': this.get('suggestion.text'),
          'translated': true,
          'focused': true,
          'fromSuggest': true
        });
        this.get('translatedChunk').get('content').save();
      }
    },

    actions: {
      process: function process() {
        this._process();
      }
    }
  });

});