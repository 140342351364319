define('cat-frontend/templates/terms', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 7862
          }
        },
        "moduleName": "cat-frontend/templates/terms.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Terms and Conditions");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Thank You for your interest in Skuuper. Please read carefully through these Terms and Condition (“Terms”), which constitute a legally binding agreement between you and us. ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","http://skuuper.com");
        var el3 = dom.createTextNode("skuuper.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" website and its related services are run by Skuuper OÜ, registered at Pärnu mnt 130, 11317, Tallinn, Estonia (hereafter referred to as “Skuuper” “us”, “we”). We provide a platform for translating content provided by users, referred to as “materials”. ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Using ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","http://skuuper.com");
        var el3 = dom.createTextNode("skuuper.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" and ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","http://cat.skuuper.com");
        var el3 = dom.createTextNode("cat.skuuper.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" Services ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You acknowledge that these Terms are binding for your use of all services provided by Skuuper on ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","http://skuuper.com");
        var el3 = dom.createTextNode("skuuper.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" and  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","http://cat.skuuper.com");
        var el3 = dom.createTextNode("cat.skuuper.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" websites. ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Skuuper grants you personal, non-exclusive, time-limited, non-transferable, non-licensable and revocable license to use skuuper.com and cat.skuuper.com websites and its Services, for so long as you comply with these Terms.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("All rights not expressly granted in these Terms are reserved. All copyright and any other intellectual property rights in relation to ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("Services");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" shall remain the sole and exclusive property of Skuuper and you shall have no claim to it insofar as not stated differently herein. ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Responsibilities");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("By registering an account on skuuper.com and cat.skuuper.com websites, you agree:");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("ol");
        var el2 = dom.createElement("li");
        var el3 = dom.createTextNode("to maintain the confidentiality of your password and other information related to the security of your account; ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createTextNode("to be fully responsible for all use of your account and for any actions that take place through your account, including responsibility for compliance with all laws and regulations that are applicable to you or the content you use in connection with Services. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Our Services are not intended for confidential or sensitive documents and you agree not to submit materials that include any such information.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Skuuper reserves the right to suspend or terminate its Services immediately to any user, whose activity is disrupting, causing harm to the Services infrastructure, or violates these Terms.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You are not allowed to use the translation that is provided to you through the Services for offering competing translation services.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("The Service, translations bank, features and functionality are and will remain the exclusive property of Skuuper. The Services are protected by copyright, trademark, and other laws. Our trademarks may not be used in connection with any product or service without the prior written consent.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("You have the right to restrict materials from inclusion to the translation memory database in your account settings.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("You can upload your own translation memory to your account for personal use and/or contribute it to our translations bank.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Inappropriate Use");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You are not allowed to upload, display or otherwise provide on or through the Services any content that is libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise violates any law or infringes upon the right of any third party");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Paid Services");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Some of our Services are priced. The fee depends on the ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("volume and specificity");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" of the materials and shall be agreed before translation of the material. The paid Services will be provided once the applicable fee has been paid via the means set forth on the website. ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("If a user is from the EU VAT zone, we detect their location and charge VAT, which is applied in the particular country. VAT will be added to the price and transmitted to local authorities using the MOSS system.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Refund policy");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("You confirm that you are informed that the 14-day right of withdrawal is not applicable due to the reason that the Service provides you personalized digital content.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Refunds will be provided to subscriptions longer than one month. Refunds will be provided proportionally to the unused subscription period and will be transferred to the same account from which the payment for the subscription was made within 5 working days after we received the service termination notice. No refunds are provided for subscriptions of one month or less.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Limitation of Liability");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Our Services are provided on an “as is” basis and without warranties of any kind, neither expressed nor implied. Although we try to provide high quality Services, we do not warrant that our Services will be error-free and uninterrupted, nor do we warrant the correctness of our translations.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("To the maximum extent permitted by applicable law, Skuuper and its directors, employees, partners and agents, are not liable for any direct, indirect, incidental, special or consequential damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with your use of our Services.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("i");
        var el3 = dom.createTextNode("We assume no liability for non-operability or impaired accessibility of Services due to, but without limitation to, technical difficulties and dependence on the services of third parties.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You are solely responsible for creating backup copies of your content that you submit to our Services.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Indemnification");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You agree to defend, indemnify and hold harmless Skuuper and our employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of (i) your use and access of the Service by you or any person using your account and password; (ii) a breach of these Terms, or (iii) content posted on the Service.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Links to Other Web Sites");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Our Service may contain links to third party web sites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Changes");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("We reserve the right, at our sole discretion, to modify or replace these Terms at any time. In case of a revision of the material we will provide at least 14 days notice on our website and by e-mail prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Services after those revisions become effective, you agree to be bound by the revised terms.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Governing Law");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("These Terms shall be governed and construed in accordance with the laws of the Republic of Estonia, without regard to any choice of laws or conflicts of law provisions. You agree that any lawsuit arising from or related to our Services shall be brought exclusively to courts located in Tallinn, Estonia and you consent to the exclusive jurisdiction of such courts. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Contact Us");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("If you have any questions about these Terms, please contact us at info@skuuper.com and cat.skuuper.com");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Copyright Infringement");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("If you are a copyright owner and have a good faith belief that any material available through our Services infringe upon your copyright, you may submit a copyright infringement notification to us on the following address:");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"href","mailto:info@skuuper.com");
        var el2 = dom.createTextNode("info@skuuper.com.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("In the copyright infringement notification please provide your contact information and as detailed information as possible of the infringement.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});