define('cat-frontend/components/document-controls', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    eventsService: Ember['default'].inject.service('events'),

    tagName: 'span',
    showPopup: false,
    attachElement: null,
    saving: false,
    deleting: false,
    inDocument: false,

    chosenFormat: null,

    chosenTxt: Ember['default'].computed.equal('chosenFormat', 'txt'),
    chosenPdf: Ember['default'].computed.equal('chosenFormat', 'pdf'),
    chosenDocx: Ember['default'].computed.equal('chosenFormat', 'docx'),
    chosenDoc: Ember['default'].computed.equal('chosenFormat', 'doc'),
    chosenHTML: Ember['default'].computed.equal('chosenFormat', 'html'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('eventsService').on('setInDocument', (function (data) {
        this.set('inDocument', data);
      }).bind(this));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('eventsService').off('setInDocument');
    },

    close: function close() {
      this.set('chosenFormat', null);
      this.get('child').toggle();
    },

    actions: {
      requestExport: function requestExport(format) {
        var self = this;
        var document = this.get('document');
        var counter = 0;

        switch (format) {
          case 'txt':
            this.set('chosenFormat', 'txt');
            break;
          case 'pdf':
            this.set('chosenFormat', 'pdf');
            break;
          case 'docx':
            this.set('chosenFormat', 'docx');
            break;
          case 'doc':
            this.set('chosenFormat', 'doc');
            break;
          case 'html':
            this.set('chosenFormat', 'html');
            break;
        }

        var onSuccess = function onSuccess(document) {
          var url = document.get('translatedFileUrl');

          if (!(url && url.split('.').pop() === self.get('chosenFormat')) && ++counter < 20) {
            Ember['default'].run.later(this, function () {
              document.reload().then(onSuccess, function () {
                console.error('Error');
              });
            }, 500);
          } else if (counter >= 20) {
            self.close();
            swal('Error', 'an error #1 occurred, please try again later', 'error');
          } else {
            self.close();
            window.location.href = self.get('document.translatedFileUrl');
          }
        };

        this.get('document').requestExport(format).then(function () {
          return document.reload().then(onSuccess);
        }, function () {
          self.close();
          swal('Error', 'Error downloading file', 'error');
          console.error('Error downloading file');
        });
      },
      togglePopup: function togglePopup() {
        this.set('attachElement', this.$().find('.js-popup-target'));
        this.set('showPopup', true);
      },
      closePopup: function closePopup() {
        this.set('showPopup', false);
      },
      erase: function erase() {
        this.get('eventsService').trigger('erase');
      },
      saveDocument: function saveDocument() {
        var self = this;
        this.set('saving', true);
        this.get('document').save().then(function () {
          self.set('saving', false);
        });
      },
      deleteDocument: function deleteDocument() {
        var document = this.get('document');
        var self = this;
        var title = document.get('title');

        swal({
          title: "Warning!",
          text: 'Do you really want to delete <span class="sa__file"> "' + title + '"</span> file?',
          html: true,
          imageUrl: "assets/trash.svg",
          imageSize: "100x110",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it!",
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          self.set('deleting', true);
          document.destroyRecord().then(function () {
            self.sendAction('goTo');
            swal({
              imageUrl: "assets/success.svg",
              imageSize: "100x110",
              title: "Success",
              html: true,
              text: 'Successfully deleted <span class="sa__file"> "' + title + '"</span> file'
            });
          }, function () {
            swal('Error', 'something went wrong', 'error');
          });
        });
      }
    }
  });

});