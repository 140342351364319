define('cat-frontend/models/document', ['exports', 'ember-data', 'ember', 'ember-validations', 'cat-frontend/config/environment'], function (exports, DS, Ember, EmberValidations, config) {

  'use strict';

  exports['default'] = (function () {
    var Document;

    Document = DS['default'].Model.extend(EmberValidations['default'], {
      title: DS['default'].attr('string'),
      text: DS['default'].attr('string'),
      file: DS['default'].attr('raw'),
      state: DS['default'].attr('string'),
      stage: DS['default'].attr('string'),
      stageProgress: DS['default'].attr('number'),
      createdAt: DS['default'].attr('date'),
      updatedAt: DS['default'].attr('date'),
      isOriginal: DS['default'].attr('boolean', { defaultValue: true }),
      translatedFileUrl: DS['default'].attr('string'),

      useMt: DS['default'].attr('boolean', { defaultValue: false }),

      owner: DS['default'].belongsTo('user', { inverse: 'documents', async: true }),
      language: DS['default'].belongsTo('language', { inverse: 'documents', async: true }),
      translateTo: DS['default'].belongsTo('language', { async: true }),
      original: DS['default'].belongsTo('document', { inverse: 'translation', async: true }),
      translation: DS['default'].belongsTo('document', { inverse: 'original', async: true }),
      chunks: DS['default'].hasMany('chunk', { inverse: 'document', async: true }),
      chunksSorting: ['position'],
      topics: DS['default'].hasMany('topics', { inverse: 'document', async: true }),
      metadata: DS['default'].attr(),

      isntValid: Ember['default'].computed.not('isValid'),

      pagesNumber: Ember['default'].computed.alias('metadata.pages_count'),

      translatedChunk: null,
      translatedChunkChanged: Ember['default'].observer('chunks.@each.translating', function () {
        var chunk = this.get('chunks').findBy('translating', true);
        if (chunk) {
          this.set('translatedChunk', chunk);
        }
      }),

      langChanged: Ember['default'].observer('language', function () {
        if (this.get('language.code') === this.get('translateTo.code')) {
          this.set('translateTo', null);
        }
      }),

      isPolling: false,

      topicIds: Ember['default'].computed.map('topics', function (topic) {
        return topic.get('id');
      }),

      isConverting: Ember['default'].computed('state', function () {
        return this.get('state') === 'converting';
      }),
      isConverted: Ember['default'].computed('state', function () {
        return this.get('state') === 'converted';
      }),
      isTranslating: Ember['default'].computed('state', function () {
        return this.get('state') === 'translating';
      }),
      isMachineTranslation: Ember['default'].computed('state', function () {
        return this.get('state') === 'machine_translation';
      }),
      isTranslated: Ember['default'].computed('state', function () {
        return this.get('state') === 'translated';
      }),
      isFailed: Ember['default'].computed('state', function () {
        return this.get('state') === 'failed';
      }),

      rootChunks: Ember['default'].computed('chunks', 'chunks.[]', 'chunks.@each.isRoot', function () {
        return this.get('chunks').filterBy('isRoot', true);
      }),
      sortedChunks: Ember['default'].computed.sort('rootChunks', 'chunksSorting'),

      translatedChunksAmount: Ember['default'].computed('chunks.@each.isTranslated', function () {
        return this.get('chunks').filterBy('isTranslated', true).get('length') || 0;
      }),

      translatableChunksAmount: Ember['default'].computed('chunks.@each.isTranslatable', function () {
        return this.get('chunks').filterBy('isTranslatable', true).get('length') || 0;
      }),

      translatedStats: Ember['default'].computed('translatedChunksAmount', 'translatableChunksAmount', function () {
        return this.get('translatedChunksAmount') / this.get('translatableChunksAmount');
      }),

      showChunks: Ember['default'].computed('isOriginal', 'chunks.isFulfilled', 'isTranslating', function () {
        return (this.get('isOriginal') || this.get('isTranslating')) && this.get('chunks.isFulfilled');
      }),

      /* Actions */

      startTranslation: function startTranslation() {
        var modelName = this.constructor.modelName;
        var adapter = this.store.adapterFor(modelName);
        return adapter.ajax(adapter.buildURL(modelName, this.get('id')) + '/start_translation', 'POST', {
          data: {
            translate_to_id: this.get('translateTo.id'),
            use_mt: this.get('useMt'),
            topic_ids: this.get('topicIds')
          }
        });
      },

      concordance: function concordance(word) {
        var modelName = this.constructor.modelName;
        var adapter = this.store.adapterFor(modelName);

        return adapter.ajax(
        // `${config.APP.apiURL}/suggestions/concordance`,
        adapter.buildURL('Suggestion') + '/concordance', 'GET', {
          'data': {
            fragment: word,
            from: this.get('language.code'),
            to: this.get('translateTo.code'),
            limit: 5
          }
        });
      },

      requestExport: function requestExport(format) {
        var modelName = this.constructor.modelName;
        var adapter = this.store.adapterFor(modelName);

        return adapter.ajax(adapter.buildURL(modelName, this.get('id')) + '/export', 'POST', { data: { export_format: format } });
      }

    });

    Document.reopen({
      validations: {
        title: {
          presence: true
        },
        file: {
          presence: true
        },
        translateToId: {
          presence: true
        }
      }
    });
    return Document;
  })();

});