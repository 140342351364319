define('cat-frontend/templates/components/editable-select', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 265
            }
          },
          "moduleName": "cat-frontend/templates/components/editable-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","editable_input");
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"class","title__input");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","ok fa fa-check-circle-o fa-lg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","cancel fa fa-times-circle-o fa-lg");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [0]);
          var element8 = dom.childAt(element7, [0]);
          var element9 = dom.childAt(element7, [1]);
          var element10 = dom.childAt(element7, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element8, 'value');
          morphs[1] = dom.createElementMorph(element9);
          morphs[2] = dom.createElementMorph(element10);
          return morphs;
        },
        statements: [
          ["attribute","value",["get","selectedValue",["loc",[null,[1,59],[1,72]]]]],
          ["element","action",["saveEdit"],["on","click"],["loc",[null,[1,99],[1,131]]]],
          ["element","action",["cancelEdit"],["on","click"],["loc",[null,[1,177],[1,211]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 489
                },
                "end": {
                  "line": 1,
                  "column": 773
                }
              },
              "moduleName": "cat-frontend/templates/components/editable-select.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","row");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-sm-9");
              var el3 = dom.createElement("li");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-sm-2");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3,"class","fa fa-pencil");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var element1 = dom.childAt(element0, [0, 0]);
              var element2 = dom.childAt(element0, [1, 0]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element1, 'selected');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element1,0,0);
              morphs[3] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["attribute","selected",["get","option.selected",["loc",[null,[1,624],[1,639]]]]],
              ["element","action",["changeOption",["get","option",["loc",[null,[1,593],[1,599]]]]],["on","click"],["loc",[null,[1,569],[1,612]]]],
              ["content","option.value",["loc",[null,[1,642],[1,658]]]],
              ["element","action",["editOption",["get","option",["loc",[null,[1,716],[1,722]]]]],["on","click"],["loc",[null,[1,694],[1,735]]]]
            ],
            locals: ["option"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 393
              },
              "end": {
                "line": 1,
                "column": 787
              }
            },
            "moduleName": "cat-frontend/templates/components/editable-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            var el2 = dom.createElement("li");
            var el3 = dom.createTextNode("Add..");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element4);
            morphs[1] = dom.createMorphAt(element3,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addOption"],["on","click"],["loc",[null,[1,445],[1,478]]]],
            ["block","each",[["get","arrangedOptions",["loc",[null,[1,497],[1,512]]]]],[],0,null,["loc",[null,[1,489],[1,782]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 265
            },
            "end": {
              "line": 1,
              "column": 800
            }
          },
          "moduleName": "cat-frontend/templates/components/editable-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","select_container");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [0]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [0]),0,0);
          morphs[1] = dom.createAttrMorph(element6, 'class');
          morphs[2] = dom.createMorphAt(element5,2,2);
          return morphs;
        },
        statements: [
          ["content","title",["loc",[null,[1,309],[1,318]]]],
          ["attribute","class",["concat",["fa ",["subexpr","if",[["get","selectOpened",["loc",[null,[1,343],[1,355]]]],"fa-caret-up","fa-caret-down"],[],["loc",[null,[1,338],[1,387]]]]]]],
          ["block","if",[["get","selectOpened",["loc",[null,[1,399],[1,411]]]]],[],0,null,["loc",[null,[1,393],[1,794]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 807
          }
        },
        "moduleName": "cat-frontend/templates/components/editable-select.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","editing",["loc",[null,[1,6],[1,13]]]]],[],0,1,["loc",[null,[1,0],[1,807]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});