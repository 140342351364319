define('cat-frontend/tests/helpers/destroy-app.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers');
  QUnit.test('helpers/destroy-app.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'helpers/destroy-app.js should pass jshint.'); 
  });

});