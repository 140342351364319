define('cat-frontend/tests/unit/initializers/skuuper-oauth2-test', ['ember', 'cat-frontend/initializers/skuuper-oauth2', 'qunit'], function (Ember, skuuper_oauth2, qunit) {

  'use strict';

  var registry, application;

  qunit.module('Unit | Initializer | skuuper oauth2', {
    beforeEach: function beforeEach() {
      Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        registry = application.registry;
        application.deferReadiness();
      });
    }
  });

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    skuuper_oauth2.initialize(registry, application);

    // you would normally confirm the results of the initializer here
    assert.ok(true);
  });

});