define('cat-frontend/components/check-box', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'i',
    classNameBindings: [':fa', 'checked:fa-check-square-o:fa-square-o', 'checked::padding-right-015em'],

    click: function click(e) {
      this.toggleProperty('checked');
    }
  });

});