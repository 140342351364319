define('cat-frontend/components/l-select', ['exports', 'ember', 'cat-frontend/mixins/click-outside'], function (exports, Ember, ClickOutside) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(ClickOutside['default'], {
    tagName: 'span',
    selectOpened: false,
    classNameBindings: ['isOpened:form-select-opened'],

    isOpened: (function () {
      return this.get('selectOpened');
    }).property('selectOpened'),

    selectedValue: Ember['default'].computed('selected', function () {
      var field = this.get('field');
      var selected = this.get('selected');

      return selected ? field ? selected.get(field) : selected : undefined;
    }),

    title: Ember['default'].computed('selectedValue', 'selectOpened', function () {
      var defaultTitle = this.get('defaultTitle');
      var selectedValue = this.get('selectedValue');
      var selectOpened = this.get('selectOpened');

      return selectOpened ? defaultTitle : selectedValue ? selectedValue : defaultTitle;
    }),

    arrangedOptions: Ember['default'].computed('options.[]', 'selected', function () {
      var field = this.get('field');
      var selected = this.get('selected');
      var selectedValue = selected ? field ? selected.get(field) : selected : undefined;

      return this.get('options').map(function (item) {
        var value = field ? item.get(field) : item;

        return {
          object: item,
          value: value,
          selected: selectedValue === value
        };
      });
    }),

    click: function click(e) {
      this.toggleProperty('selectOpened');
    },

    onOutsideClick: function onOutsideClick() {
      if (this.get('selectOpened')) {
        this.toggleProperty('selectOpened');
      }
    },

    actions: {
      changeOption: function changeOption(option) {
        this.set('selected', option.object);
      }
    }

  });

});