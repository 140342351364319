define('cat-frontend/components/document-table-header', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    isDown: true,
    sortLink: Ember['default'].computed('sort', 'isDown', function () {
      if (this.get('isDown')) {
        return this.get('sort') + ':desc';
      }
      return this.get('sort');
    }),
    mouseDown: function mouseDown() {
      this.toggleProperty('isDown');
    }
  });

});