define('cat-frontend/adapters/application', ['exports', 'cat-frontend/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'active-model-adapter'], function (exports, config, DataAdapterMixin, ActiveModelAdapter) {

  'use strict';

  ActiveModelAdapter['default'].reopen({
    coalesceFindRequests: true
  });

  exports['default'] = ActiveModelAdapter['default'].extend(DataAdapterMixin['default'], {
    host: config['default'].APP.apiURL,
    namespace: 'api',
    authorizer: 'authorizer:devise',
    shouldReloadAll: function shouldReloadAll() {
      return false;
    }
  });

});