define('cat-frontend/templates/components/document-controls', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 276
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","controls__list_li js-popup-target");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","controls");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-download fa-lg");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","controls__text padding-top-2px");
          var el4 = dom.createTextNode("Download");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [0, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["element","action",["togglePopup"],["bubbles",false,"on","click"],["loc",[null,[1,106],[1,155]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 276
            },
            "end": {
              "line": 1,
              "column": 455
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","controls__list_li");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","controls");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-download fa-lg disabled");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","controls__text disabled padding-top-2px");
          var el4 = dom.createTextNode("Download");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 574
            },
            "end": {
              "line": 1,
              "column": 633
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 633
            },
            "end": {
              "line": 1,
              "column": 674
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1,"class","fa fa-trash fa-lg");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 793
              },
              "end": {
                "line": 1,
                "column": 949
              }
            },
            "moduleName": "cat-frontend/templates/components/document-controls.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"class","fa fa-caret-square-o-right fa-lg");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","controls__text padding-top-2px");
            var el2 = dom.createTextNode("Translate");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 746
            },
            "end": {
              "line": 1,
              "column": 966
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","controls__list_li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["document",["get","document",["loc",[null,[1,815],[1,823]]]]],["class","controls"],0,null,["loc",[null,[1,793],[1,961]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1302
              },
              "end": {
                "line": 1,
                "column": 1359
              }
            },
            "moduleName": "cat-frontend/templates/components/document-controls.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1359
              },
              "end": {
                "line": 1,
                "column": 1399
              }
            },
            "moduleName": "cat-frontend/templates/components/document-controls.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"class","fa fa-save fa-lg");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 973
            },
            "end": {
              "line": 1,
              "column": 1469
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","controls__list_li");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","controls");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-eraser fa-lg");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","controls__text padding-top-2px");
          var el4 = dom.createTextNode("Erase");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","controls__list_li");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","controls");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","controls__text padding-top-2px");
          var el4 = dom.createTextNode("Save");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [0, 0]);
          var element7 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["erase",["get","document",["loc",[null,[1,1041],[1,1049]]]]],["bubbles",false,"on","click"],["loc",[null,[1,1024],[1,1076]]]],
          ["element","action",["saveDocument",["get","document",["loc",[null,[1,1249],[1,1257]]]]],["bubbles",false,"on","click"],["loc",[null,[1,1225],[1,1284]]]],
          ["block","if",[["get","saving",["loc",[null,[1,1308],[1,1314]]]]],[],0,1,["loc",[null,[1,1302],[1,1406]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1771
                },
                "end": {
                  "line": 1,
                  "column": 1831
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1831
                },
                "end": {
                  "line": 1,
                  "column": 1877
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-file-pdf-o fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2060
                },
                "end": {
                  "line": 1,
                  "column": 2121
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2121
                },
                "end": {
                  "line": 1,
                  "column": 2168
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-file-word-o fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2351
                },
                "end": {
                  "line": 1,
                  "column": 2411
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2411
                },
                "end": {
                  "line": 1,
                  "column": 2458
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-file-word-o fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2640
                },
                "end": {
                  "line": 1,
                  "column": 2700
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2700
                },
                "end": {
                  "line": 1,
                  "column": 2747
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-file-text-o fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child8 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2930
                },
                "end": {
                  "line": 1,
                  "column": 2991
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-refresh fa-spin fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child9 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.10",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 2991
                },
                "end": {
                  "line": 1,
                  "column": 3038
                }
              },
              "moduleName": "cat-frontend/templates/components/document-controls.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1,"class","fa fa-file-code-o fa-lg");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1493
              },
              "end": {
                "line": 1,
                "column": 3104
              }
            },
            "moduleName": "cat-frontend/templates/components/document-controls.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","document-format__list");
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","document-format__list_li");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","document-format");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","document-format__text");
            var el5 = dom.createTextNode("PDF");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","document-format__list_li");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","document-format");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","document-format__text");
            var el5 = dom.createTextNode("DOCX");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","document-format__list_li");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","document-format");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","document-format__text");
            var el5 = dom.createTextNode("DOC");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","document-format__list_li");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","document-format");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","document-format__text");
            var el5 = dom.createTextNode("TXT");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","document-format__list_li");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","document-format");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","document-format__text");
            var el5 = dom.createTextNode("HTML");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var element1 = dom.childAt(element0, [0, 0]);
            var element2 = dom.childAt(element0, [1, 0]);
            var element3 = dom.childAt(element0, [2, 0]);
            var element4 = dom.childAt(element0, [3, 0]);
            var element5 = dom.childAt(element0, [4, 0]);
            var morphs = new Array(10);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1,0,0);
            morphs[2] = dom.createElementMorph(element2);
            morphs[3] = dom.createMorphAt(element2,0,0);
            morphs[4] = dom.createElementMorph(element3);
            morphs[5] = dom.createMorphAt(element3,0,0);
            morphs[6] = dom.createElementMorph(element4);
            morphs[7] = dom.createMorphAt(element4,0,0);
            morphs[8] = dom.createElementMorph(element5);
            morphs[9] = dom.createMorphAt(element5,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["requestExport","pdf"],["bubbles",false,"on","click"],["loc",[null,[1,1689],[1,1746]]]],
            ["block","if",[["get","chosenPdf",["loc",[null,[1,1777],[1,1786]]]]],[],0,1,["loc",[null,[1,1771],[1,1884]]]],
            ["element","action",["requestExport","docx"],["bubbles",false,"on","click"],["loc",[null,[1,1977],[1,2035]]]],
            ["block","if",[["get","chosenDocx",["loc",[null,[1,2066],[1,2076]]]]],[],2,3,["loc",[null,[1,2060],[1,2175]]]],
            ["element","action",["requestExport","doc"],["bubbles",false,"on","click"],["loc",[null,[1,2269],[1,2326]]]],
            ["block","if",[["get","chosenDoc",["loc",[null,[1,2357],[1,2366]]]]],[],4,5,["loc",[null,[1,2351],[1,2465]]]],
            ["element","action",["requestExport","txt"],["bubbles",false,"on","click"],["loc",[null,[1,2558],[1,2615]]]],
            ["block","if",[["get","chosenTxt",["loc",[null,[1,2646],[1,2655]]]]],[],6,7,["loc",[null,[1,2640],[1,2754]]]],
            ["element","action",["requestExport","html"],["bubbles",false,"on","click"],["loc",[null,[1,2847],[1,2905]]]],
            ["block","if",[["get","chosenHTML",["loc",[null,[1,2936],[1,2946]]]]],[],8,9,["loc",[null,[1,2930],[1,3045]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1476
            },
            "end": {
              "line": 1,
              "column": 3119
            }
          },
          "moduleName": "cat-frontend/templates/components/document-controls.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","drop-popup",[],["attachElement",["subexpr","@mut",[["get","attachElement",["loc",[null,[1,1521],[1,1534]]]]],[],[]],"closePopup","closePopup","classes","document-format__container","register-as",["subexpr","@mut",[["get","child",["loc",[null,[1,1608],[1,1613]]]]],[],[]]],0,null,["loc",[null,[1,1493],[1,3119]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 3126
          }
        },
        "moduleName": "cat-frontend/templates/components/document-controls.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("li");
        dom.setAttribute(el1,"class","controls__list_li");
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","controls");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","controls__text padding-top-2px");
        var el4 = dom.createTextNode("Delete");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [1, 0]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createElementMorph(element9);
        morphs[2] = dom.createMorphAt(element9,0,0);
        morphs[3] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[4] = dom.createMorphAt(fragment,3,3,contextualElement);
        morphs[5] = dom.createMorphAt(fragment,4,4,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","or",[["get","document.isTranslating",["loc",[null,[1,10],[1,32]]]],["get","document.isTranslated",["loc",[null,[1,33],[1,54]]]]],[],["loc",[null,[1,6],[1,55]]]]],[],0,1,["loc",[null,[1,0],[1,462]]]],
        ["element","action",["deleteDocument",["get","document",["loc",[null,[1,521],[1,529]]]]],["bubbles",false,"on","click"],["loc",[null,[1,495],[1,556]]]],
        ["block","if",[["get","deleting",["loc",[null,[1,580],[1,588]]]]],[],2,3,["loc",[null,[1,574],[1,681]]]],
        ["block","if",[["get","translate",["loc",[null,[1,752],[1,761]]]]],[],4,null,["loc",[null,[1,746],[1,973]]]],
        ["block","if",[["get","inDocument",["loc",[null,[1,979],[1,989]]]]],[],5,null,["loc",[null,[1,973],[1,1476]]]],
        ["block","if",[["get","showPopup",["loc",[null,[1,1482],[1,1491]]]]],[],6,null,["loc",[null,[1,1476],[1,3126]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});