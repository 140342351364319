define('cat-frontend/routes/documents/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      return this.store.query('document', { originals: true });
    },
    afterModel: function afterModel(model) {
      if (!model.get('length')) {
        this.transitionTo('documents.new');
      }
    }
  });

});