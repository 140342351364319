define('cat-frontend/components/radial-progress', ['exports', 'ember', 'cat-frontend/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      Ember['default'].run.next(this, function () {
        this.$().find('.radial-progress').css('opacity', '1');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().find('.radial-progress').css('opacity', '0');
    },
    hash: ENV['default'].documentStateMap,
    text: Ember['default'].computed('stage', 'stage!', function () {
      var text;
      var st = this.get('stage!');
      if (st) {
        text = this.get('hash')[st];
      } else {
        text = this.get('hash')[this.get('stage')];
      }
      return text ? text : 'We are almost there';
    }),
    hiddenNumbers: Ember['default'].computed('number', 'number!', function () {
      return !Ember['default'].isPresent(this.get('number')) && !Ember['default'].isPresent(this.get('number!'));
    }),
    hiddenPulse: Ember['default'].computed.not('hiddenNumbers'),
    percentage: Ember['default'].computed('number', 'number!', function () {
      var num = this.get('number!');
      if (num) {
        return num;
      } else {
        var number = this.get('number');
        return Ember['default'].isPresent(number) ? number : 0;
      }
    }),
    stageWithNumbers: false,
    observer: Ember['default'].observer('stage', function () {
      var stage = this.get('stage');
      if (stage === 'chunking' || stage === 'searching_tm') {
        this.set('stageWithNumbers', true);
        this.set('lastStage', stage);
      } else if (this.get('stageWithNumbers') && stage !== 'chunking' && stage !== 'searching_tm') {
        this.set('number!', 100);
        this.set('stage!', this.get('lastStage'));
        Ember['default'].run.later(this, function () {
          if (this.get('isDestroyed')) {
            return;
          }
          this.setProperties({
            'number!': null,
            'stage!': null
          });
        }, 500);
      }
    })
  });

});