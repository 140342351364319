define('cat-frontend/tests/integration/components/l-select-test.jshint', function () {

  'use strict';

  QUnit.module('JSHint - integration/components');
  QUnit.test('integration/components/l-select-test.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'integration/components/l-select-test.js should pass jshint.'); 
  });

});