define('cat-frontend/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, Ember, ApplicationRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ApplicationRouteMixin['default'], {
    model: function model() {
      return this.store.findAll('language', { reload: true });
    },
    actions: {
      didTransition: function didTransition() {
        Ember['default'].$(".js-main-spinner").fadeOut("fast");
      }
    }
  });

});