define('cat-frontend/components/tm-controls', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: '',
    actions: {
      deleteTM: function deleteTM() {
        var tm = this.get('tm');
        var title = tm.get('name');

        swal({
          title: "Warning!",
          text: 'Do you really want to delete <span class="sa__file"> "' + title + '"</span> file?',
          html: true,
          imageUrl: "assets/trash.svg",
          imageSize: "100x110",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it!",
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          tm.destroyRecord().then(function () {
            swal({
              imageUrl: "assets/success.svg",
              imageSize: "100x110",
              title: "Success",
              html: true,
              text: 'Successfully deleted <span class="sa__file"> "' + title + '"</span> file'
            });
          }, function () {
            swal('Error', 'something went wrong', 'error');
          });
        });
      }
    }
  });

});