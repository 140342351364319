define('cat-frontend/services/faye', ['exports', 'ember', 'cat-frontend/config/environment', 'ic-ajax'], function (exports, Ember, config, ic_ajax) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    client: null,
    store: Ember['default'].inject.service('store'),

    init: function init() {
      this._super.apply(this, arguments);
      this.subscribe('/recordUpdated', this.get('onRecordUpdated'));
    },

    subscribe: function subscribe(channel, callback, binding) {
      if (!binding) {
        binding = this;
      }
      var privatePubCallback = callback.bind(binding);
      ic_ajax.request(config['default'].APP.apiURL + '/users/private_pub.json', {
        method: 'GET',
        data: { channel: channel }
      }).then(function (data) {
        console.debug('Subscribing to ' + channel, data.json);

        PrivatePub.sign(data.json);
        PrivatePub.subscribe(channel, function (data, channel) {
          privatePubCallback(data, channel);
        });
      });
    },

    unsubscribe: function unsubscribe(channel) {
      PrivatePub.unsubscribe(channel);
    },

    onRecordUpdated: function onRecordUpdated(payload, channel) {
      console.debug('Received message on ' + channel, payload);
      this.get('store').pushPayload(payload);
    }
  });

});