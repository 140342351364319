define('cat-frontend/models/chunk', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    //document: DS.belongsTo('document', {async: true}),
    document: DS['default'].belongsTo('document', { inverse: 'chunks', async: true }),
    language: DS['default'].belongsTo('language', { inverse: 'chunks', async: true }),
    original: DS['default'].belongsTo('chunk', { inverse: 'translation', async: true }),
    translation: DS['default'].belongsTo('chunk', { inverse: 'original', async: true }),
    chunkType: DS['default'].attr('string'),
    text: DS['default'].attr('string'),
    position: DS['default'].attr('number'),
    pageNumber: DS['default'].attr('number'),
    translated: DS['default'].attr('boolean'),
    //data: DS.attr('string'),
    parent: DS['default'].belongsTo('chunk', { inverse: 'children', async: true }),
    children: DS['default'].hasMany('chunk', { inverse: 'parent', async: true }),
    suggestions: DS['default'].hasMany('suggestion', { inverse: 'chunk', async: true }),
    chunksSorting: ['position'],

    sortedChunks: Ember['default'].computed.sort('children', 'chunksSorting'),

    page: Ember['default'].computed.alias('pageNumber'),

    translating: false,
    focused: false,
    fromSuggest: DS['default'].attr('boolean', { serialize: true }),

    hasSuggestions: Ember['default'].computed('suggestions', function () {
      return !!this.get('suggestions.length');
    }),

    hasMTResults: Ember['default'].computed('suggestions.firstObject.suggestionType', function () {
      return this.get('suggestions.firstObject.suggestionType') === 'mt';
    }),

    firstSuggestion: Ember['default'].computed.alias('suggestions.firstObject.text'),

    isRoot: Ember['default'].computed('parent', function () {
      return this.get('parent.isEmpty') !== false;
    }),
    isOriginal: Ember['default'].computed.alias('document.isOriginal'),
    isTranslation: Ember['default'].computed.not('isOriginal'),
    isTranslatable: Ember['default'].computed('isLoaded', 'isTranslation', 'original.{text,isLoaded}', function () {
      return this.get('isLoaded') && this.get('isTranslation') && this.get('original.isLoaded') && Ember['default'].isPresent(this.get('original.text'));
    }),

    isTranslated: Ember['default'].computed('isTranslation', 'text', 'original.text', function () {
      return this.get('isTranslation') && this.get('text') !== this.get('original.text');
    }),
    isntTranslated: Ember['default'].computed.not('isTranslated'),

    tagName: Ember['default'].computed('chunkType', function () {
      var chunkType = this.get('chunkType');
      if (Ember['default'].isEmpty(chunkType)) {
        return null;
      }
      switch (chunkType) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
        case 'p':
        case 'ul':
        case 'ol':
        case 'li':
        case 'table':
          return chunkType;
        case 'image':
          return 'img';
        case 'sentence':
        case 'container':
          return 'span';
        default:
          return 'span';
      }
    })
  });

});