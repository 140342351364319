define('cat-frontend/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    showModal: false,
    session: Ember['default'].inject.service('session'),

    pathChanged: Ember['default'].observer('currentPath', function () {
      this.set('renderAddNewDocumentButton', this.get('currentPath') !== 'documents.new');
      this.set('renderControls', this.get('currentPath') === 'documents.document');
    }),

    renderAddNewDocumentButton: false,
    renderControls: false,

    actions: {
      goTo: function goTo() {
        this.transitionToRoute('documents');
      },

      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });

});