define('cat-frontend/components/language-selector', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    eventsService: Ember['default'].inject.service('events'),

    opened: false,
    clicked: false,

    labelId: Ember['default'].computed('label', function () {
      return '#' + this.get('label');
    }),
    chosenLanguage: Ember['default'].computed('selectedValue', function () {
      var name = this.get('selectedValue.name');
      return name ? name : 'Choose language';
    }),

    observerClick: Ember['default'].observer('opened', function () {
      var opened = this.get('opened');
      if (!opened) {
        Ember['default'].run.later(this, function () {
          this.set('clicked', opened);
        }, 300);
      } else {
        this.set('clicked', opened);
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('eventsService').on('collapse', (function (label) {
        if (label === this.get('label')) {
          if (this.get('anotherOpened')) {
            this.toggleOpened(300);
          } else {
            this.toggleOpened(0);
          }
        } else if (label !== this.get('label') && this.get('opened')) {
          this.send('toggleDropDown');
        }
      }).bind(this));

      Ember['default'].$(document).on('click', this.get('handleOutsideClick').bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('eventsService').off('collapse');
      Ember['default'].$(document).off('click', this.get('handleOutsideClick'));
    },

    handleOutsideClick: function handleOutsideClick(event) {
      var $target = Ember['default'].$(event.target);

      if (!$target.closest(Ember['default'].$('.js-lang')).length && this.get('opened')) {
        this.send('toggleDropDown');
      }
    },

    setHeight: function setHeight() {
      var height = this.$('.js-lang-list').outerHeight();
      this.$('.js-lang-drop-down').height(height);
    },

    toggleOpened: function toggleOpened(delay) {
      if (delay) {
        Ember['default'].run.later(this, function () {
          this.set('opened', !this.get('opened'));
          this.setHeight();
        }, delay);
      } else if (delay === 0) {
        this.set('opened', !this.get('opened'));
        this.setHeight();
      } else {
        this.set('opened', !this.get('opened'));
      }
    },

    actions: {
      langChosen: function langChosen(data) {
        this.set('selectedValue', this.get('languages').findBy('code', data));
      },
      toggleDropDown: function toggleDropDown() {
        var opened = this.get('opened');
        if (!opened) {
          this.sendAction('setSelectState', this.get('label'), !opened);
          this.get('eventsService').trigger('collapse', this.get('label'));
        } else {
          this.sendAction('setSelectState', this.get('label'), !opened);
          this.$('.js-lang-drop-down').height(0);
          this.toggleOpened();
        }
      }
    }
  });

});